import './bbutil'
import BBIFrame from './BBIFrame'

class BBTable extends BBIFrame {
  constructor() {
    super()

    this.scrolling = 'true'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBTable',
      component: BBTable,
      label: 'BB Table widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the map. Use ?uri and ?geometry for WKT strings. Optionally use ?color for automatic coloring ',
        },
        {
          name: 'renderEmpty',
          type: 'boolean',
          label: 'render an empty table',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Published uri variabele for other widgets.',
        },
        {
          name: 'searchbar',
          type: 'boolean',
          label:'show searchbar',
        },
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }

  getUrl() {
    // console.log("BB Table should getURL");

    return '/bb/w2ui/table_1.html'
  }
}

export default BBTable
