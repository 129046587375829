import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'

export default class BBVenn extends BBIFrame {
  constructor() {
    super()
    this.scrolling = 'false'
    this.noEvents = false
  }

  getUrl() {
    // console.log("BB Table should getURL");

    return '/bb/w2ui/venn.html'
  }

  static getDescription() {
    return {
      name: 'BBVenDiagram',
      component: BBVenn,
      label: 'BB Venn diagram',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'use ?set and ?amount.  ?set values must be space separated classes. ',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },

        PublishUtils.getResetValueDef(),
      ],
    }
  }
}
