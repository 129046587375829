import React, { Component } from 'react'
import styles from './Table.module.css'
import {
  restructureResponse,
  generateEmptyResponse,
  aggregateResultsByFirstParameter,
} from '../../helpers/sparql'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import PublishUtils from './../../helpers/PublishUtils'

// import { Table as StrapTable } from 'reactstrap';

function camelCaseToSpaces(str) {
  str = str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ').toLowerCase()
  str=str.replace("_"," ");
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const check = <FontAwesomeIcon icon={faCheck} color="green" />
const cross = <FontAwesomeIcon icon={faTimes} color="red" />

class Table extends Component {
  onClick = (uri) => {

    if (uri==null) return;
    if (uri.length<2) return;

    if ((uri!=null) && (uri=="http://www.example.org/5c3c96ea1d83d8396b87bc0b") ) return;
   

    PublishUtils.resetParameter(this)
    this.props.publish(this.props.publishVariable, uri)
  }
  openLink = (url) => {
    if (url==null) return;
    if (url.length<2) return;
    window.open(url, '_blank')
  }

  static getDescription() {
    return {
      name: 'Table',
      component: Table,
      label: 'Table widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill table. If table item should be a link, than the you should add a property with the same name with suffix "URI", eg: ?object ?objectURI.',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which item URI is published, when a link in the table is clicked.',
        },
        {
          name: 'highlightVariable',
          type: 'text',
          label: 'highlight table row with this uri',
        },
        {
          name: 'ashtml',
          type: 'boolean',
          label: 'tabel waardes als html',
        },
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de tabel',
        },
        {
          name: 'renderEmpty',
          type: 'boolean',
          label: 'render an empty table',
        },
        {
          name: 'zeroResult',
          type: 'text',
          label:
            'text to display when sparql results is valid but has zero results',
        },
        {
          name: 'aggregateResultsByFirstParameter',
          type: 'boolean',
          label: 'aggregate sparql results by the first parameter',
        },
        {
          name: 'hasNumberedRows',
          type: 'boolean',
          label: 'show row numbers',
        },
        {
          name: 'centerContent',
          type: 'boolean',
          label: 'center values in the tabel',
        },
        PublishUtils.getResetValueDef(),
      ],
    }
  }

  

  render() {
   
    var data = this.props.data;
    //if (data ==null) console.log("Table renderer heeft geen data",this.props.pubsub.datasets);
    if ( this.props.data==null || this.props.data.results==null || this.props.data.results.bindings==null ||this.props.data.results.bindings.length == 0) {
      if (this.props.renderEmpty) {
        data = generateEmptyResponse(this.props.query)
        // console.log("table rende empty ",this.props.query, this.props);
      } else {
        try {
          if (this.props.zeroResult != null) {
            if (
              this.props.zeroResult != null &&
              this.props.data.results &&
              this.props.data.results.bindings.length === 0
            ) {
              var label = this.props.zeroResult
              return (
                <div className={styles.main}>
                  <p>{label}</p>
                </div>
              )
            }
          }
        } catch (e) {}
        
        return null
      }
    }
   // console.log("rendering table not empty");

    let selectedUri = null
    if (this.props.highlightVariable != null) {
      let parameter = this.props.highlightVariable
      selectedUri = this.props.pubsub[parameter]
    }
    let headVars = data.head.vars
    let items = restructureResponse(data)

    if (this.props.aggregateResultsByID) {
      items = aggregateResultsByFirstParameter(items, headVars[0])
    }
    let center=styles.centertext;
   if (this.props.centerContent!=true) center="";


    var html = false
    if (this.props.ashtml) html = true

    return (
      <div className={styles.main}>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        <table>
          <thead>
            <tr>
              {this.props.hasNumberedRows && (
                <th className={styles.rowNumberHeader +"  "+center} key={-1}></th>
              )}
              {headVars.map((headVar, index) => {
                if (headVar.endsWith('URI')) return null
                if (headVar.endsWith('URL')) return null

                return <th className={center} key={index}>{camelCaseToSpaces(headVar)}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              return (
                <tr key={index}>
                  {this.props.hasNumberedRows && (
                    <td className={styles.rowNumber+" "+center}>{index + 1}</td>
                  )}
                  {headVars.map((headVar, index) => {
                    if (headVar.endsWith('URI')) return null
                    if (headVar.endsWith('URL')) return null

                    let value = item[headVar] || ''
                    let uri = item[headVar + 'URI']
                    let url = item[headVar + 'URL']

                    if (uri) {
                      let highlight = ''
                      if (uri === selectedUri) {
                        highlight = styles.highlight
                        // console.log("found a selection",highlight);
                      }

                      return (
                        <td 
                          key={index}
                          className={center}
                          
                        >
                          {html ? (
                            <div onClick={() => this.onClick(uri)} className={styles.link + ' ' + highlight+" "+styles.inlineblock}  dangerouslySetInnerHTML={{ __html: value }} />
                          ) : (
                            <div onClick={() => this.onClick(uri)} className={styles.link + ' ' + highlight+" "+styles.inlineblock} >{value}</div>
                            
                          )}
                        </td>
                      )
                    } else {
                      if (url) {
                        return (
                          <td
                            key={index}
                            className={center}
                          
                          >
                            {html ? (
                              <div className={styles.link+"  "+styles.inlineblock}
                              onClick={() => this.openLink(url)} dangerouslySetInnerHTML={{ __html: value }}
                              />
                            ) : (
                              <div   onClick={() => this.openLink(url)} className={styles.link+"  "+styles.inlineblock} >{value}</div>
                              
                            )}
                          </td>
                        )
                      } else {
                        let anders = null
                        if (value === 'true') anders = check
                        if (value === 'false') anders = cross
                        if (anders != null) {
                          return (
                            <td key={index} className={styles.iconcenter+" "+center}>
                              {anders}
                            </td>
                          )
                        }

                        return (
                          <td key={index} className={center}>
                            {html ? (
                              <div
                                dangerouslySetInnerHTML={{ __html: value }}
                              />
                            ) : (
                              value
                            )}
                          </td>
                        )
                      }
                    }
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Table
