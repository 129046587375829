import React, { Component, Fragment } from 'react'
import styles from './BBAccordion.module.css'
import {
  restructureResponse,
  generateEmptyResponse,
} from '../../../helpers/sparql'
import { Accordion, Icon } from 'semantic-ui-react'
import 'semantic-ui-css/components/accordion.css' //deze moet wel

//import './BBAccordion.css'; //deze moet wel

// import { Table as StrapTable } from 'reactstrap';

export default class BBAccordion extends Component {
  state = { activeIndex: -1 }

  static getDescription() {
    return {
      name: 'Accordion',
      component: BBAccordion,
      label: 'Accordion',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'use parameter ?label for accordion headings. use ?content for inner accordion content ',
        },

        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de tabel',
        },

        {
          name: 'html',
          type: 'boolean',
          label: 'html text',
        },
      ],
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    let data = this.props.data
    let html = this.props.html

    if (!data || data.results.bindings.length === 0) {
      if (this.props.renderEmpty) {
        data = generateEmptyResponse(this.props.query)
      } else {
      }
    }
    if (!data || data.results.bindings.length === 0) {
      return null
    }
    //console.log(data);

    let items = restructureResponse(data)

    if (!items.length)
      return (
        <Fragment>
          {' '}
          <div
            className={
              styles.main +
              (this.props.isFirstItemBold ? ' ' + styles.firstItemBold : '')
            }
          >
            {this.props.title && (
              <div className={styles.title}>{this.props.title}</div>
            )}{' '}
          </div>
        </Fragment>
      )

    const { activeIndex } = this.state
    try {
      return (
        <div
          className={
            styles.main +
            (this.props.isFirstItemBold ? ' ' + styles.firstItemBold : '')
          }
        >
          {this.props.title && (
            <div className={styles.title}>{this.props.title}</div>
          )}
          <Accordion fluid styled>
            {data.results.bindings.map((binding, index) => {
              if (binding.label.value == null || binding.label.value === '') {
                return null
              }
              return (
                <Fragment key={index}>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={this.handleClick}
                  >
                    <Icon name="dropdown" />
                    {binding.label.value}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === index}>
                    {
                      (html ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: binding.content.value,
                          }}
                        />
                      ) : (
                        <p> {binding.content.value} </p>
                      ))
                    }
                  </Accordion.Content>
                </Fragment>
              )
            })}
          </Accordion>
        </div>
      )
    } catch (e) {}
    return null
  }
}
