import { Component } from 'react'
import { connect } from 'react-redux'
import './App.css'
import { BrowserRouter as Router, Route } from 'react-router-dom'
//import { HashRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home'
import Users from './components/Users'
import Login from './components/Login'
import Loginbb from './components/Loginbb'
import Project from './components/Project'
import Viewer from './components/Viewer'
import Spinner from './components/Spinner'
import axios from 'axios'

const ERR_INVALID_RESPONSE_WITH_SUGGESTION =
  'Invalid response! When developing use `yarn dev`.'

class App extends Component {
  componentDidMount() {
    if (window.CONNECTED_APPS_STATIC) {
      return this.props.dispatch({
        type: 'FETCH_ME_SUCCESS',
        me: {
          isAuthenticated: false,
        },
      })
    }

    axios({
      method: 'get',
      url: '/api/me',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}`,
        withCredentials: true,
      },
    })
      .then(({ data }) => {
        if (!data.me) throw new Error(ERR_INVALID_RESPONSE_WITH_SUGGESTION)
        this.props.dispatch({
          type: 'FETCH_ME_SUCCESS',
          me: data.me,
        })
      })
      .catch((error) => {
        localStorage.removeItem('kvk-vb-token')
        this.props.dispatch({
          type: 'FETCH_ME_FAILURE',
        })
      })
  }

  render() {
    if (this.props.me.loading) return <Spinner />

    if (window.CONNECTED_APPS_STATIC) {
      return (
        <Router>
          <Route path="" exact component={Viewer} />
          <Route path="/loginbb" component={Loginbb} />
        </Router>
      )
    }

    return (
      <Router>
        <div className="app">
          <Route path="/" exact component={Home} />
          <Route path="/loginbb" component={Loginbb} />
          <Route path="/login" component={Login} />
          <Route path="/users" component={Users} />
          <Route path="/projects/:projectPath" exact component={Project} />
          <Route
            path="/projects/:projectPath/:viewerPath/:mode(edit)?"
            component={Viewer}
          />
          <Route
            path="/iets/projects/:projectPath/:viewerPath/:mode(edit)?"
            component={Viewer}
          />
        </div>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  me: state.me,
})

export default connect(mapStateToProps)(App)
