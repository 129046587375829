import React, { Component, Fragment } from 'react'
import styles from './RadioBox.module.css'

class RadioBox extends Component {
  static getDescription() {
    return {
      name: 'RadioBox',
      component: RadioBox,
      label: 'Radio box widget',
      variables: [
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which the URI of the selected radio box is published.',
        },
        {
          name: 'label',
          type: 'text',
          label: 'Label',
        },
        {
          name: 'horizontal',
          type: 'boolean',
          label: 'horizontal',
        },
        {
          name: 'options',
          type: 'text',
          label:
            'Options defined by a comma separated list of value/label pairs',
        },
      ],
    }
  }

  componentDidMount() {
    let options = this.restructureOptions()
    if (!options) return;
    if (false)  
    {
    this.props.publish(
      this.props.publishVariable,
      options[0] && options[0].value
    )
    }
  }

  onChange = (event) => {
    let value = event.target.value
    console.log(value)
    this.props.publish(this.props.publishVariable, value)
  }

  restructureOptions() {
    let options = this.props.options
    try {
      options = options.split(',')
    } catch (error) {
      return null
    }

    options = options.reduce((org, value, index) => {
      if (index % 2 === 0) {
        value = value.trim()
        org = [
          ...org,
          {
            value,
            label: options[index + 1] || value,
          },
        ]
      }
      return org
    }, [])
    return options
  }

  renderOptionsHorizontal() {
    let value = this.props.pubsub[this.props.publishVariable]
    let options = this.restructureOptions()

    if (!options) {
      return (
        <div>Options should be a comma separated list of value,label pairs</div>
      )
    }

    return (
      <Fragment>
        {options.map((option) => (
          <div key={option.value}>
            <label className={styles.radioContainerHor}>
              <input
                type="radio"
                name={this.props.label}
                checked={value === option.value}
                value={option.value}
                onChange={this.onChange}
              />
              <span
                style={{
                  verticalAlign: 'middle',
                  paddingRight: '15px',
                  paddingLeft: '12px',
                }}
              >
                {option.label}
              </span>
            </label>
          </div>
        ))}
      </Fragment>
    )
  }
  renderOptionsVertical() {
    let value = this.props.pubsub[this.props.publishVariable]
    let options = this.restructureOptions()

    if (!options) {
      return (
        <div>Options should be a comma separated list of value,label pairs</div>
      )
    }

    return (
      <div>
        {options.map((option) => (
          <div key={option.value}>
            <label className={styles.radioContainerVer}>
              <input
                type="radio"
                name={this.props.label}
                checked={value === option.value}
                value={option.value}
                onChange={this.onChange}
              />
              <span style={{ verticalAlign: 'middle', paddingLeft: '12px' }}>
                {option.label}
              </span>
            </label>
          </div>
        ))}
      </div>
    )
  }

  render() {
    let horizontal = this.props.horizontal
    return (
      <div className={styles.main}>
        <div className={styles.label}>{this.props.label} </div>

        {horizontal
          ? this.renderOptionsHorizontal()
          : this.renderOptionsVertical()}
      </div>
    )
  }
}

export default RadioBox
