import { Component } from 'react'
import { Icon } from './Icon'
import styles from './SequencePaths.module.css'

const WIDGET_VARIABLES = [
  {
    name: 'query',
    type: 'yasgui',
    label:
      'Query to display sequence path. ' +
      'The following variable-names should be queried: "?name", "?uri", "?parentUri". ' +
      'The utmost-grand-parent is displayed on the left, with the utmost-grand-child on the right.' +
      'If there are multiple parents, then multiple sequence paths will be shown.'
  },
  {
    name: 'iconName',
    type: 'text',
    label: 'font-awesome-icon-name to be used between path-nodes (default: "angle-right")',
  },
  {
    name: 'topicOnClick',
    type: 'text',
    label: 'Topic to publish URI when clicking on path-node',
  },
]

export class SequencePaths extends Component {
  state = {
    sequences: [],
    data: undefined,
    previousData: undefined,
  }

  static getDescription() {
    return {
      name: 'SequencePaths',
      component: SequencePaths,
      label: 'Sequence-paths widget',
      variables: WIDGET_VARIABLES,
    }
  }

  render = () => (
    <div className={`${styles.main} ${styles[this.props.panelstyle]}`} disabled={this.state.loading}>
      {this.state.sequences.map((sequence) => (
        <div>
          {sequence.map((entry, i) => (
            <>
              {i !== 0 && <Icon key={`icon ${i}`} icon={this.props.iconName?.length ? this.props.iconName : 'angle-right'} />}
              <span
                className={`${styles.pathSegment} ${this.props.panelstyle}`}
                key={entry.uri.value}
                onClick={() => this.select(entry.uri.value)}
              >
                {entry.name.value}
              </span>
            </>
          ))}
        </div>
      ))}
    </div>
  )

  componentDidUpdate = () => {
    try {
      if (this.props.data && this.props.data !== this.state.previousData) {
        const sequences = sequenceRows(this.props.data.results.bindings)
        this.setState({ sequences, previousData: this.props.data })
      }
    } catch(e) {
      console.error(e.message)
    }
  }

  select = (uri) => this.props.dispatch({ type: 'PUBLISH', data: { [this.props.topicOnClick]: uri } })
}

function sequenceRows(rows) {
  const { rowByUri, rowsByParentUri } = makeHashMaps()
  const rootRows = rows.filter(r => !(r.parentUri?.value in rowByUri))
  return rootRows.flatMap(r => getSequences([[r]]))

  function makeHashMaps() {
    const rowByUri = {}
    const rowsByParentUri = {}

    for (const row of rows) {
      rowByUri[row.uri.value] = row

      if (!row.parentUri?.value) continue
      if (!rowsByParentUri[row.parentUri.value]) rowsByParentUri[row.parentUri.value] = [row]
      else rowsByParentUri[row.parentUri.value].push(row)
    }

    return { rowByUri, rowsByParentUri }
  }

  function getSequences(sequences) {
    return sequences.flatMap(sequence => {
      const parent = sequence.at(-1)
      const children = rowsByParentUri[parent.uri.value]
      if (!children) return [sequence]

      const newSequences = children.map(c => [...sequence, c])
      return getSequences(newSequences)
    })
  }
}
