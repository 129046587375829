import React, { Component } from 'react'
import { Paper } from '@material-ui/core'
import Card from '@material-ui/core/Card'
//import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent'

import styles from './BBStructuredText.module.css'

import PublishUtils from './../../../helpers/PublishUtils'

export default class BBStructuredText extends Component {
  state = {}
  static id = 0

  static getDescription() {
    return {
      name: 'BBStructuredText',
      component: BBStructuredText,
      label: 'BB Structured Text',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter ?header ?headerText ?content  ',
        },
        {
          name: 'paperbackground',
          type: 'select',
          label: 'paper background',
          options: [
            {
              label: 'default',
              value: 'noneWhite',
            },
            {
              label: 'grey',
              value: 'bbgrey',
            },
            {
              label: 'white',
              value: 'bbwhite',
            },
            {
              label: 'default grey',
              value: 'noneGrey',
            },
          ],
        },
        {
          name: 'papercolor',
          type: 'select',
          label: 'paper color',
          options: [
            {
              label: 'default',
              value: 'noneWhite',
            },
            {
              label: 'white paper',
              value: 'bbwhitePaper',
            },
            {
              label: 'grey paper',
              value: 'bbgreyPaper',
            },

            {
              label: 'default grey',
              value: 'noneGrey',
            },
          ],
        },

        {
          name: 'cls',
          type: 'text',
          label: 'class ',
        },
      ],
    }
  }
  shouldComponentUpdate_werktniet(arg, arg2) {
    PublishUtils.emptyDataParameterChange(this, arg, arg2)
    return true
  }

  componentDidUpdate() {
    // console.log("componentDiDupdate ",this.selectedID);
    var me = this
    if (this.selectedID != null) {
      setTimeout(function () {
        const element = document.getElementById(me.selectedID)
        if (element != null) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 50)
    } else {
      // reset scroll
      setTimeout(function () {
        const element = document.getElementById('begin')
        if (element != null) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 50)
    }
  }

  render() {
    this.selectedID = null
    var de = (
      <div>
        {' '}
        <Paper elevation={13}> </Paper>
      </div>
    )

    let data = this.props.data

    if (data == null) {
      return de
    }
    if (data.results == null) {
      return de
    }

    let items = data.results.bindings
    var previousItem = null
    var nextPreviousItem = null
    let cls = this.props.cls
    return (
      <div className={cls}>
        <Card
          className={styles.card + ' ' + styles[this.props.paperbackground]}
        >
          <CardContent
            className={styles.cc + ' ' + styles[this.props.papercolor]}
            elevation={13}
          >
            {items.map((item, index) => {
              previousItem = nextPreviousItem
              var extraID = null
              if (item.selected != null) {
                if (item.selected.value) {
                  if (this.selectedID == null) {
                    // first time
                    BBStructuredText.id++
                    this.selectedID = 'bbst' + BBStructuredText.id
                    extraID = this.selectedID
                  }
                }
              }
              nextPreviousItem = item
              return (
                <HtmlRow
                  {...this.props}
                  extraID={extraID}
                  key={index}
                  index={index}
                  previousItem={previousItem}
                  item={item}
                />
              )
            })}
          </CardContent>
        </Card>
      </div>
    )
  }
}

class HtmlRow extends Component {
  processHeader(header, selected) {
    if (header == null) {
      return null
    }
    var hs = header.split('/')
    var size = hs.length + 1
    var last = hs[hs.length - 1]
    var style = styles.heen
    if (selected) {
      style = styles.selectedH
    }
    if (size === 1) {
      return <h1 className={style}> {last}</h1>
    }
    if (size === 2) {
      return <h2 className={style}> {last}</h2>
    }
    if (size === 3) {
      return <h3 className={style}> {last}</h3>
    }
    if (size === 4) {
      return <h4 className={style}> {last}</h4>
    }
    if (size === 5) {
      return <h5 className={style}> {last}</h5>
    }
  }
  isListHeader(header) {
    if (header == null) {
      return false
    }
    if (header.includes('/')) {
      return false
    }
    if (header.includes('.')) {
      return true
    }
    return false
  }

  render() {
    var inList = false

    var item = this.props.item
    var extraID = this.props.extraID
    var pitem = this.props.previousItem
    //  console.log(pitem);
    var content = null
    var content2 = null
    if (item.content != null) {
      content = item.content.value
    }
    var headerText = null
    if (item.headerText != null) {
      headerText = item.headerText.value
    }
    var header = null
    var header2 = null
    var selected = false
    try {
      selected = item.selected.value
    } catch (e) {}

    if (item.header != null) {
      header = item.header.value
      if (this.isListHeader(header)) {
        inList = true
        header2 = header
        content2 = content
        content = null
        header = null
      } else {
        var pheader = null
        try {
          pheader = pitem.header.value
        } catch (ee) {}
        if (pheader === header) {
          header = null //one time only
        } else {
          header = this.processHeader(header, selected)
        }
      }
    }

    if (selected) {
      return (
        <div id={extraID}>
          {headerText && <h1 className={styles.selected}>{headerText}</h1>}
          {header}
          {inList && (
            <table>
              <tbody>
                <tr>
                  <td className={styles.left}>
                    <p className={styles.selected}>{header2}</p>
                  </td>
                  <td>
                    <p className={styles.selected}>{content2}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {content && <p className={styles.selected}>{content}</p>}
        </div>
      )
    } else {
      return (
        <div>
          {headerText && <h1 className={styles.heen}>{headerText}</h1>}
          {header}
          {inList && (
            <table>
              <tbody>
                <tr>
                  <td className={styles.left}>
                    <p className={styles.parLi}>{header2}</p>
                  </td>
                  <td>
                    <p className={styles.par}>{content2}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          {content && <p className={styles.par}>{content}</p>}
        </div>
      )
    }
  }
}
