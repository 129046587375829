//import {GraphQLVoyager} from 'graphql-voyager';
//import '../../../../node_modules/graphql-voyager/dist/voyager.css';
import BBIFrame from './BBIFrame'
import './bbutil'

//var hans =require ('./test.json');

class BBVoyager extends BBIFrame {
  static getDescription() {
    return {
      name: 'BBVoyager',
      component: BBVoyager,
      label: 'BB Voyager',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'to fill the diagram. ( ?domainName ?rangeName ?propname)',
        },
        {
          name: 'height',
          type: 'string',
          label: 'optional css string for the height value',
        },
      ],
    }
  }

  getUrl() {
    BBIFrame.scrolling = 'no'
    return '/bb/bbvoyager.html'
  }
}

export default BBVoyager
