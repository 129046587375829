import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'

class BBCesium extends BBIFrame {
  constructor() {
    super()

    this.scrolling = 'no'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBCesiumJS',
      component: BBCesium,
      label: 'Cesium JS',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: ' Use ?uri ?geometry ?color',
        },
        {
          name: 'basequery',
          type: 'yasgui',
          label: ' Use ?uri ?geometry ?color',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        PublishUtils.getResetValueDef(),
      ],
    }
  }

  state = { selectedUri: null }

  getUrl() {
    //return "/bb/rdfviewer/indexNew.html";
    return '/bb/static/iframes/cesiumMap/cesiumConf.html'
  }
}
export default BBCesium
