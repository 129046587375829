import React, { Fragment } from 'react'
import styles from './ButtonPopup.module.css'

import {
  Button,
  IconButton,
  Typography,
  DialogContent,
  Tooltip,
} from '@material-ui/core'

//import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Icon from '@material-ui/core/Icon'

import AbstractArea from '../../../AbstractArea'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Grow from '@material-ui/core/Grow'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Area from '../../../Area'
import CloseIcon from '@material-ui/icons/Close'

export default class ButtonPopup extends AbstractArea {
  state = { openDialog: false, areas: [] }

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow direction="up" ref={ref} {...props} unmountOnExit />
  })

  handleButtonClick = () => {
    let updatepopup = Math.random() * 1000033 + 'f'

    this.props.publish('updatepopup', updatepopup)
    this.setState({ openDialog: true })
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false })
  }

  static getDescription() {
    return {
      name: 'BB ButtonPopup',
      component: ButtonPopup,
      label: 'Button Popup',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'button label',
        },
        {
          name: 'beginIcon',
          type: 'text',
          label: 'begin icon',
        },
        {
          name: 'endIcon',
          type: 'text',
          label: 'end icon',
        },

        {
          name: 'popupFullScreen',
          type: 'boolean',
          label: 'popup fullScreen',
        },
        {
          name: 'popupTitle',
          type: 'text',
          label: 'popupTitle',
        },

        {
          name: 'tooltip',
          type: 'text',
          label: 'button tooltip',
        },
        {
          name: 'closeAtParameterChange',
          type: 'text',
          label: 'close popup when the parameter changes',
        },
        {
          name: 'openDialog',
          type: 'boolean',
          label: 'open dialog the first time',
        },
      ],
    }
  }

  componentDidMount() {
    if (this.props.openDialog) {
      let updatepopup = Math.random() * 1000033 + 's'
      this.props.publish('updatepopup', updatepopup)
      this.setState({ openDialog: true })
    }
  }

  shouldComponentUpdate(props, p) {
    if (this.state.openDialog !== p.openDialog) return true

    if (this.state.openDialog) {
      if (this.props.mode === 'edit') return true
      if (this.props.closeAtParameterChange != null) {
        let parameter = this.props.closeAtParameterChange
        let nprop = props.pubsub[parameter]
        let value = this.props.pubsub[parameter]

        if (value !== nprop) {
          this.setState({ openDialog: false })
        }
      }
    }

    return false
  }

  render() {
    let title = this.props.title
    if (title == null) {
      title = ''
    }
    let tooltip = this.props.tooltip
    if (tooltip == null) {
      tooltip = ''
    }
    let popupTitle = this.props.popupTitle
    if (popupTitle == null) {
      popupTitle = ''
    }

    let beginIcon = this.props.beginIcon
    let endIcon = this.props.endIcon
    let openDialog = this.state.openDialog
    let fullScreen = this.state.popupFullScreen
    if (fullScreen == null) fullScreen = false
    let fullWidth = true

    let areaConfig = {}
    try {
      areaConfig = this.props.areas[0]
    } catch (e) {}

    return (
      <Fragment>
        <Tooltip title={tooltip}>
          <Button
            className={styles.openButton}
            size="small"
            endIcon={endIcon && <Icon>{endIcon}</Icon>}
            startIcon={beginIcon && <Icon>{beginIcon}</Icon>}
            onClick={() => this.handleButtonClick()}
            variant="contained"
          >
            {title}
          </Button>
        </Tooltip>

        <Dialog
          fullScreen={fullScreen}
          fullWidth={fullWidth}
          open={openDialog}
          TransitionComponent={this.Transition}
          onClose={this.handleCloseDialog}
          aria-labelledby="iets"
        >
          {fullScreen ? (
            <Fragment>
              <AppBar className={styles.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={this.handleCloseDialog}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={styles.title}>
                    {popupTitle}
                  </Typography>
                </Toolbar>
              </AppBar>
              <br></br>
              <br></br>
              <br></br>
              <Area {...this.props} />
            </Fragment>
          ) : (
            <Fragment>
              <DialogTitle id="customized-dialog-title">
                {popupTitle}{' '}
                <IconButton
                  className={styles.closeButton}
                  onClick={this.handleCloseDialog}
                >
                  {' '}
                  <CloseIcon />{' '}
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Area
                  {...this.props}
                  config={areaConfig}
                  index={0}
                  onChange={this.props.onChange}
                  closeDialog={this.handleCloseDialog}
                />
              </DialogContent>
            </Fragment>
          )}
        </Dialog>
      </Fragment>
    )
  }
}
