import { Component, Fragment } from 'react'
import styles from './Area.module.css'
import gearWhite from '../images/gear-white.svg'
import gearBlue from '../images/gear-blue.svg'
import insertIcon from '../images/plus.svg'
import infoIcon from '../images/info.svg'
import widgetDefinitions from './widgets'
import deleteIcon from '../images/cross.svg'
import Widget from './Widget'
import Dialog from './Dialog'
import { Button } from 'reactstrap'
import FormField from './FormField'
import PublishUtils from '../helpers/PublishUtils'
import { Grow, Tooltip, Button as MaterialButton } from '@material-ui/core'
import { getCgiUrl } from '../helpers/url'

class Area extends Component {
  state = {
    dialogVisible: false,
  }

  onInsertLayout = (name) => {
    let config = {
      ...this.props.config,
    }
    var split = null
    if (
      window.confirm(
        'Do you want this widget to be the first element in the new splitlayout'
      )
    ) {
      split = {
        areaSizes: ['auto', 'auto'],
        areas: [config, {}],
        direction: 'column',
        widget: 'SplitLayout',
      }
    } else {
      if (
        window.confirm(
          'Do you want this widget to be the second element in the new splitlayout'
        )
      ) {
        split = {
          areaSizes: ['auto', 'auto'],
          areas: [{}, config],
          direction: 'column',
          widget: 'SplitLayout',
        }
      } else {
        if (
          window.confirm(
            'Do you want this widget to be the third element in the new splitlayout or cancel all'
          )
        ) {
          split = {
            areaSizes: ['auto', 'auto', 'auto'],
            areas: [{}, {}, config],
            direction: 'column',
            widget: 'SplitLayout',
          }
        } else {
          return
        }
      }
    }

    this.props.onChange(split, this.props.index)
  }

  componentDidMount() {
    if (!document.areaOnlyOnce) {
      this.handleDefaultParameterValues()
      document.areaOnlyOnce = true
      //  console.log("area did mount ",this);
    }
  }

  handleDefaultParameterValues() {
    // console.log("handle default parameter values",this);
    try {
      //cgi parameter
      const cgi = getCgiUrl('', this.props.repoURL)
      this.props.publish('cgi', cgi)
      let params = this.props.parameterValues
      if (params == null) return

      params = JSON.parse(params)

      for (let n in params) {
        //console.log("publishing ",n,params[n]);
        this.props.publish(n, params[n])
        //this.props[n]=params[n];
      }
    } catch (e) {
      console.log(
        'error parsing default values json',
        this.props.parameterValues,
        e
      )
    }
  }

  onDeleteWidget = (name) => {
    if (window.confirm('are you sure that you want to delete this?')) {
      let config = {
        ...this.props.config,
      }

      config = {}
      this.props.onChange(config, this.props.index)
    }
  }

  isLayoutWidget(widget) {
    if (widget === 'SplitLayout' || widget === 'SplitLayoutTest') {
      return true
    }

    if (widget === 'BBKVKProductTemplate') {
      return true
    }
    // if (widget=='Tab')  {   return true; }

    if (widget === 'BB ButtonPopup') {
      return true
    }

    return false
  }

  onFormChange = (name, value) => {
    // console.log("form change ",name,value);

    let config = {
      ...this.props.config,
      [name]: value,
    }

    if (name === 'widget') {
      //console.log(value);
      if (this.isLayoutWidget(value)) {
        // zodat we geen rare invoegsels krijgen dat doen we via de plus knop

        config.direction = 'column'
        config.areas = [this.props.config]
        if (value === 'SplitLayoutTest') {
          config.areaSizes = ['1', '1']
        } else {
          config.areaSizes = ['200px', '1']
        }
      } else if (value === 'Tab' || value === 'BBSideMenu') {
        config.tabs = ['Tab 1', 'Tab 2']
        config.areas = [this.props.config]
      } else {
        let moveConfig =
          config.areas &&
          config.areas.find((area) => area && area.widget === value)
        if (moveConfig) config = moveConfig
      }
    }
    this.props.onChange(config, this.props.index)
  }

  onChildChange = (config, index) => {
    let newConfig = {
      ...this.props.config,
    }
    newConfig.areas[index] = config
    this.props.onChange(newConfig, this.props.index)
  }

  onShowPopupInfo = () => {
    this.setState({ popupInfoVisible: true })
    let me = this
    setTimeout(function () {
      me.infoPopupClose()
    }, 2500)
  }

  infoPopupClose = () => {
    this.setState({ popupInfoVisible: false })
  }

  onDialogOkClick = () => {
    this.setState({ dialogVisible: false })
    this.props.save()
  }

  disableUserActionsIfSynced = (event) => {
    if (
      this.props.config.syncWithUserActionsDisabled &&
      this.props.userActionsDisabled
    )
      event.stopPropagation()
  }

  renderWidget(config, widgetDefinition) {
    if (!config.widget) {
      if (this.props.mode === 'edit') return <div>Selecteer een widget.</div>
      return
    }
   
   
    return (
      <Widget
        repoURL={this.props.repoURL}
        parameterValues={this.props.parameterValues}
        localRepoURL={this.props.localRepoURL}
        mode={this.props.mode}
        save={this.props.save}
        sparqlErrorMessage={this.props.sparqlErrorMessage}
        withinSelectedTab={this.props.withinSelectedTab}
        {...config}
        definition={widgetDefinition}
        onChange={this.onChildChange}
        closeDialog={this.props.closeDialog}
      
      >
        <widgetDefinition.component />
      </Widget>
    )
  }

  render() {
    let config = this.props.config || {}
    let menubar = false
    let titleMenuBar = config.titleMenubar
    if (titleMenuBar != null) {
      titleMenuBar = titleMenuBar.trim()
      titleMenuBar = PublishUtils.processStringForParameters(this, titleMenuBar)
    }
    if (titleMenuBar != null && titleMenuBar !== '' && titleMenuBar !== ' ') {
      menubar = true
    }
    //  console.log("titleMenubar '"+titleMenuBar+"'");

    let mode = this.props.mode
    let widgetDefinition = widgetDefinitions.find(
      (widgetDefinition) => widgetDefinition.name === config.widget
    )
    let islayout = this.isLayoutWidget(config.widget)
    let extraStyling1 = ''
    let extraStyling2 = ''
    let extraStyling3 = ''
    let extraStyling4 = ''
    if (config.panelstyleExtra != null) {
      var sts = config.panelstyleExtra.split(' ')
      try {
        extraStyling1 = sts[0]
      } catch (e) {}
      try {
        extraStyling2 = sts[1]
      } catch (e) {}
      try {
        extraStyling3 = sts[2]
      } catch (e) {}
      try {
        extraStyling4 = sts[3]
      } catch (e) {}
    }
    let displayFlex = ' ' + styles.outerAreaContainerDisplayFlex
    //not used
    if (this.props.disableFlexbox) {
      //  console.log("disable flexbox for this area")  ;
      displayFlex = ''
    }
    let infoMenubarb = false
    if (config.infoMenubar != null) {
      if (config.infoMenubar.length > 2) {
        infoMenubarb = true
      }
    }
    var appear=true;
// 
    return (
     
     <Grow appear={appear} timeout={1000} in={true}>
        <div
          disabled={this.state.loading}
          className={
            styles.outerAreaContainer +
            displayFlex +
            ' ' +
            (mode === 'edit' ? '' : styles.viewMode) +
            ' ' +
            styles[config.widget] +
            ' ' +
            styles[config.panelstyle] +
            ' ' +
            styles[extraStyling1] +
            ' ' +
            styles[extraStyling2] +
            ' ' +
            styles[extraStyling3] +
            ' ' +
            styles[extraStyling4]
          }
          onClickCapture={this.disableUserActionsIfSynced}
          onChangeCapture={this.disableUserActionsIfSynced}
        >
          {mode === 'edit' && (
            <div className={styles.hoverToEdit}>
              <div className={styles.hoverIndicator} />
              <Tooltip
                placement="bottom"
                title={
                  <MaterialButton>
                    {config.widget && `${config.widget} widget`}
                  </MaterialButton>
                }
              >
                <div className={styles.editArea}>
                  <img
                    src={insertIcon}
                    alt="box"
                    className={styles.insertIcon}
                    onClick={() => this.onInsertLayout(config.widget)}
                  />
                  <img
                    src={islayout ? gearBlue : gearWhite}
                    alt="gear"
                    className={styles.editIcon}
                    onClick={() => this.setState({ dialogVisible: true })}
                  />
                  <img
                    src={deleteIcon}
                    alt="cross"
                    className={styles.insertIcon}
                    onClick={() => this.onDeleteWidget(config.widget)}
                  />
                </div>
              </Tooltip>
            </div>
          )}

          {menubar && (
            <div className={styles.menubar}>
              <div className={styles.menubartxt}> {titleMenuBar}</div>
              {infoMenubarb && (
                <img
                  src={infoIcon}
                  alt="box"
                  className={styles.insertIcon}
                  onClick={() => this.onShowPopupInfo()}
                />
              )}
            </div>
          )}

          {this.renderWidget(config, widgetDefinition)}

          {this.state.popupInfoVisible && (
            <Dialog onClose={this.infoPopupClose}>
              <div>{config.infoMenubar}</div>
            </Dialog>
          )}

          {this.state.dialogVisible && (
            <Dialog>
              <FormField
                classforname={'dark-form-control' + styles.formElement}
                name="widget"
                label="Widget"
                type="select"
                options={[
                  { label: 'Selecteer een widget', value: '' },
                  ...widgetDefinitions.map((wd) => ({
                    label: wd.label,
                    value: wd.name,
                  })),
                ]}
                value={config.widget}
                onChange={this.onFormChange}
              />
              <FormField
                classforname="dark-form-control"
                name="panelstyle"
                label="panel style"
                type="select"
                options={[
                  { label: 'Selecteer een style', value: '' },
                  { label: 'standaard', value: 'standaard' },
                  { label: 'toolbar', value: 'toolbar' },
                  { label: 'border', value: 'border' },
                  { label: 'border 2', value: 'border2' },
                  { label: 'paper', value: 'paper' },
                  { label: 'paper 2', value: 'paper2' },
                  { label: 'padding', value: 'padding1' },
                  { label: 'padding 2', value: 'padding2' },
                  { label: 'padding 3', value: 'padding3' },
                  { label: 'none', value: 'none' },
                  { label: 'none white', value: 'noneWhite' },
                  { label: 'refresh', value: 'refresh' },
                  { label: 'three color template', value: 'threeColorTemplate' },
                ]}
                value={config.panelstyle}
                onChange={this.onFormChange}
              />
              <FormField
                name="panelstyleExtra"
                label="panel style"
                type="text"
                value={config.panelstyleExtra}
                onChange={this.onFormChange}
              />

              <FormField
                name="titleMenubar"
                label="title menubar"
                type="text"
                value={config.titleMenubar}
                onChange={this.onFormChange}
              />
              <FormField
                name="infoMenubar"
                label="Info popup menubar"
                type="text"
                value={config.infoMenubar}
                onChange={this.onFormChange}
              />
              <FormField
                name="colorPrimaryAction"
                label='give primary action color (when panel style "three color template")'
                type="boolean"
                value={config.colorPrimaryAction}
                onChange={this.onFormChange}
              />
              <FormField
                name="syncWithUserActionsDisabled"
                label="sync with global 'userActionsDisabled'."
                type="boolean"
                value={config.syncWithUserActionsDisabled}
                onChange={this.onFormChange}
              />

              {config.widget &&
                widgetDefinition.variables &&
                widgetDefinition.variables.map((variable) => (
                  <FormField
                    key={variable.name}
                    {...variable}
                    value={config[variable.name]}
                    onChange={this.onFormChange}
                  />
                ))}

              <div style={{ textAlign: 'center' }}>
                <Button
                  color="primary"
                  style={{ minWidth: '120px', marginTop: '12px' }}
                  onClick={this.onDialogOkClick}
                >
                  OK
                </Button>
              </div>
            </Dialog>
          )}
        </div>
        </Grow>
     
    )
  }
}

export default Area
