import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './Project.module.css'
import orderBy from 'lodash/orderBy'
import Header from './Header'
import add from '../images/add.svg'
import BackButton from './BackButton'
import axios from 'axios'
import Spinner from './Spinner'
import { Helmet } from 'react-helmet'
import Footer from './Footer'

function makeID() {
  var text = ''
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  return text
}

class Project extends Component {
  componentDidMount() {
    let projectPath = this.props.match.params.projectPath
    let project = this.props.project
    if (project.path === projectPath && !project.loading) return
    axios({
      method: 'get',
      url: '/api/projects/' + projectPath,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}`,
      },
    }).then(({ data }) => {
      this.props.dispatch({
        type: 'FETCH_PROJECT_SUCCESS',
        data,
      })
    })
  }

  newClicked = () => {
    let path = makeID()
    this.props.history.push(`/projects/${this.props.project.path}/${path}/edit`)
  }

  renderViewers(viewers, isAuthenticated) {
    if (!viewers.length)
      return (
        <div className={styles.noViewerMessage}>
          Geen apps gevonden... {!isAuthenticated && 'Mischien als u inlogt.'}
        </div>
      )
    return (
      <div>
        {viewers.map((viewer) => {
          return (
            <div
              className={styles.viewer}
              key={viewer.path}
              onClick={() =>
                this.props.history.push(
                  `/projects/${this.props.project.path}/${viewer.path}`
                )
              }
            >
              <div className={styles.titleText}>{viewer.title}</div>
              <div style={{ fontSize: '0.9em', opacity: '0.6' }}>
                {viewer.public ? 'publiek' : 'prive'}
              </div>
              <div style={{ fontSize: '0.9em', opacity: '0.6' }}>
                {viewer.noConfigCache ? 'no config cache' : 'use cache'}
              </div>
              {viewer.user != null && (
                <div style={{ fontSize: '0.9em', opacity: '0.6' }}>
                  {viewer.user.name}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    let project = this.props.project
    if (project.loading) return <Spinner />
    let viewers = project.viewers
    let { role, isAuthenticated } = this.props.me
    // viewers = orderBy(viewers, ['createdAt'], ['asc']);
    viewers = orderBy(viewers, ['title'], ['asc'])
    return (
      <div>
        <Helmet>
          <title>{project.title}</title>
          <link
            rel="icon"
            type="image/png"
            href={`/favicon-${project.path}.png`}
            sizes="16x16"
          />
        </Helmet>
        <BackButton to="/" />
        <Header />
        <div className={styles.mainContainer}>
          <div className={styles.main}>
            <div className={styles.content}>
              <div
                className={styles.title}
              >{`Connected Apps van ${this.props.project.title}`}</div>
              {/* <div className={styles.title}>Selecteer een viewer</div> */}
              <div className={styles.viewers}>
                {this.renderViewers(viewers, isAuthenticated)}
                {isAuthenticated && role !== 'viewer' && (
                  <div className={styles.viewer} onClick={this.newClicked}>
                    <div>
                      <img src={add} alt="add" style={{ width: '24px' }} />
                      <span
                        style={{ verticalAlign: 'middle', paddingLeft: '12px' }}
                      >
                        Nieuwe app aanmaken...
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  me: state.me,
})

export default connect(mapStateToProps)(Project)
