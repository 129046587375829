import { Component } from 'react'
import styles from '../../Project.module.css'

class BBLinkButtons extends Component {
  static getDescription() {
    return {
      name: 'BBLinkButtons',
      component: BBLinkButtons,
      label: 'BB Link Buttons',
      variables: [
        {
          name: 'labels',
          type: 'array',
          label: 'label,label2,etc',
        },
        {
          name: 'links',
          type: 'array',
          label: 'url1,url2,etc',
        },
        {
          name: 'title',
          type: 'text',
          label: 'titel',
        },
      ],
    }
  }

  render() {
    return (
      <div>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        {this.props.labels?.map((label, index) => (
          <div
            className={styles.viewer}
            key={index}
            onClick={() => window.open(this.props.links?.[index], '_blank')}
          >
            <div className={styles.titleText}>{label}</div>
          </div>
        ))}
      </div>
    )
  }
}
export default BBLinkButtons
