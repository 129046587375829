export const FETCH_VIEWER_SUCCESS = 'FETCH_VIEWER_SUCCESS'
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS'
export const SAVE_VIEWER_SUCCESS = 'SAVE_VIEWER_SUCCESS'
export const SET_USER_ACTIONS_DISABLED = 'SET_USER_ACTIONS_DISABLED'
export const SET_LAYOUT_NAME = 'SET_LAYOUT_NAME'

const initialState = {
  loading: true,
  userActionsDisabled: false,
  viewers: [],
}

export default function handleProject(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        ...action.data,
        viewers: action.data.viewers.map((viewer) => ({
          ...viewer,
          loaded: false,
        })),
        loading: false,
      }
    case FETCH_VIEWER_SUCCESS:
      window.top.data = action.data

      return {
        ...state,
        viewers: [
          ...state.viewers.filter((item) => item.id !== action.data.id),
          { ...action.data, loaded: true },
        ],
      }
    case SAVE_VIEWER_SUCCESS:
      return {
        ...state,
        viewers: [
          ...state.viewers.filter((item) => item.id !== action.data.id),
          { ...action.data, loaded: true },
        ],
      }
    case SET_USER_ACTIONS_DISABLED:
      return { ...state, userActionsDisabled: action.data }
    case SET_LAYOUT_NAME:
      return { ...state, layoutName: action.data }
    default:
      return state
  }
}
