import React from 'react'
import {
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  Menu,
  MenuItem,
} from 'react-pro-sidebar'
import styles from './BBSideMenu.module.edit.css'
import './BBSideMenu.css'

import { Tooltip } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AsbtractArea from '../../AbstractArea'
import Area from '../../Area'

import { FaBeer } from 'react-icons/fa'
//import * as Icons from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

class BBSideMenu extends AsbtractArea {
  state = {
    collapsed: false,
    selectedIndex: 0,
    hideTabs: null,
    areas: [],
  }

  componentDidMount() {
    //  console.log("check url ");
    let tab = this.props['tabSelectionSubscription']
    if (tab != null) {
      const queryString = window.top.location.search
      const urlParams = new URLSearchParams(queryString)
      const value = urlParams.get(tab)
      if (value != null) {
        try {
          const n = parseInt(value)
          this.setState({ selectedIndex: n })
        } catch (e) {
          console.log(e)
        }
      }
    }

     if  ( (this.props.startCollapsed) || (this.props.onlyCollapsed) )
     {
      this.setState({collapsed:true});
     }

  }

  static getDescription() {
    return {
      name: 'BBSideMenu',
      component: BBSideMenu,
      label: 'BBSideMenu',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill tree. First item should be the object label, the second the object URI, the third the parent URI. optional you can use icon, icon2 and icon3 variables for fa-fa icons ()',
        },
        {
          name: 'tabs',
          type: 'array',
          label: 'Tabs',
        },
        {
          name: 'tabSelectionSubscription',
          type: 'text',
          label: 'Variabele to listen to for tab selection',
        },
        {
          name: 'hideTabs',
          type: 'text',
          label:
            'Variabele to listen to for tab visualisation. Use tab names to hide the tabs',
        },
        {
          name: 'icons',
          type: 'array',
          label:
            'fa icon names for each tab such as box,cogs,coffee,envelope,hand-point-left,arrow-left,cube,database,gavel,gift,stop,',
        },
        {
          name: 'headertext',
          type: 'text',
          label: 'text for the header when expanded',
        },
        {
          name: 'lazyLoadingPages',
          type: 'boolean',
          label: 'does not load the tabs/pages in advance. Also returning to a page results in a reload',
        },

        {
          name: 'footertext',
          type: 'text',
          label: 'text for the footer',
        },
        {
          name: 'backgroundimage',
          type: 'text',
          label: 'background image',
        },
        {
          name: 'startCollapsed',
          type: 'boolean',
          label: 'start collapsed',
        },
        {
          name: 'onlyCollapsed',
          type: 'boolean',
          label: 'only collapsed',
        },

        
        {
          name: 'className',
          type: 'text',
          label: 'css classNames',
        },
        {
          name: 'areas',
          type: 'json',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label: 'area definitions',
        },
      ],
    }
  }

  componentDidUpdate(prevProps) {
    let tabSelectionSubscription = this.props.tabSelectionSubscription
    if (tabSelectionSubscription) {
      if (
        prevProps.pubsub[tabSelectionSubscription] !==
        this.props.pubsub[tabSelectionSubscription]
      ) {
        let index = this.props.pubsub[tabSelectionSubscription]
        this.setState({ selectedIndex: index })
      }
    }
    let hideTabs = this.props.hideTabs
    if (hideTabs) {
      if (prevProps.pubsub[hideTabs] !== this.props.pubsub[hideTabs]) {
        let data = this.props.pubsub[hideTabs]
        this.setState({ hideTabs: data, selectedIndex: 0 })
      }
    }
  }

  onTabClick = (index, event) => {
    if (event) {
      event.stopPropagation()
    }
    let tabSelectionSubscription = this.props.tabSelectionSubscription
    if (tabSelectionSubscription) {
      this.props.publish(tabSelectionSubscription, index)
    } else {
      this.setState({ selectedIndex: index })
    }
  }

  headerClicked = () => {

    if (this.props.onlyCollapsed) return;
    

    var collapsed = !this.state.collapsed
    this.setState({ collapsed: collapsed })
  }
  render() {


    var loadPages=true;
    if (this.props.lazyLoadingPages==true) loadPages=false;

    try {
      // <MenuItem>Component 1</MenuItem>
      let backgroundimage = this.props.backgroundimage
      let footertext = this.props.footertext
      let headertext = this.props.headertext
      let state = this.state
      let collapsed = this.state.collapsed
      let className = this.props.className
      let onlyCollapsed = this.props.onlyCollapsed
      if (onlyCollapsed==null) onlyCollapsed=false;

      return (
        <div className={'app sideMenu ' + className}>
          <ProSidebar
            collapsedWidth="46px"
            onClick={this.headerClicked}
            collapsed={state.collapsed}
            {...(backgroundimage && { image: backgroundimage })}
          >
            {!onlyCollapsed && (
            <SidebarHeader>
              {collapsed ? (
                <FontAwesomeIcon
                  preserveAspectRatio="xMaxYMin meet"
                  onClick={this.headerClicked}
                  icon={faAngleRight}
                  size="2x"
                />
              ) : (
                <FontAwesomeIcon
                  onClick={this.headerClicked}
                  icon={faAngleLeft}
                  size="2x"
                ></FontAwesomeIcon>
              )}

              {!collapsed && headertext && (
                <center>
                  <h2>{headertext}</h2>
                </center>
              )}
            </SidebarHeader>
            )}
            <SidebarContent>
              <Menu className={className} iconShape="round" popperArrow="true">
                {this.props.tabs.map((tab, index) => {
                  let selected = index === this.state.selectedIndex

                  if (this.state.hideTabs != null) {
                    if (tab === this.state.hideTabs) {
                      return null
                    }
                  }

                  let icon = <FaBeer />

                  try {
                    icon = this.props.icons[index]
                  } catch (error) {}
                  icon = <FontAwesomeIcon icon={icon} />
                  if (collapsed) {
                    return (
                      <Tooltip title={tab} key={index + 'Tooltip'}>
                        <MenuItem
                          icon={icon}
                          className={selected ? 'active' : undefined}
                          key={index + 'MenuItem'}
                          onClick={(event) => this.onTabClick(index, event)}
                        >
                          {tab}
                        </MenuItem>
                      </Tooltip>
                    )
                  } else {
                    return (
                      <MenuItem
                        icon={icon}
                        className={selected ? 'active' : undefined}
                        key={'mi' + index}
                        onClick={(event) => this.onTabClick(index, event)}
                      >
                        {tab}
                      </MenuItem>
                    )
                  }
                })}
              </Menu>
            </SidebarContent>
            <SidebarFooter>
              {footertext && (
                <center>
                  <h4>{footertext}</h4>
                </center>
              )}
            </SidebarFooter>
          </ProSidebar>

          <div className="main ">
            {this.props.tabs.map((tab, index) => {
              let areaConfig = {}
              try {
                areaConfig = this.props.areas[index] || {}
              } catch (e) {}

              let selected = index === this.state.selectedIndex
              var extra = ' main '
              if (selected || loadPages) {
                return (
                  <div
                    key={index + 'div'}
                    className={styles.areaContainer + extra}
                    style={{ display: selected ? 'flex' : 'none' }}
                  >
                    <Area
                      {...this.props}
                      key={index + 'Area'}
                      index={index}
                      config={areaConfig}
                      onChange={this.props.onChange}
                      withinSelectedTab={selected}
                    />
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      )
    } catch (error) {
      console.log(error)
      return null
    }
  }
}

export default BBSideMenu
