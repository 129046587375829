import React, { Component } from 'react'
import styles from './SelectBox.module.css'
import { Input } from 'reactstrap'
import { restructureResponse } from '../../helpers/sparql'
import { not, isEmptyObject } from '../../helpers/utils'
import isEqual from 'lodash/isEqual'

class SelectBox extends Component {
  static getDescription() {
    return {
      name: 'SelectBox',
      component: SelectBox,
      label: 'Select box widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'To fill select box items',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which the URI of the selected item is published.',
        },
        {
          name: 'publishVariable2',
          type: 'text',
          label: 'Variabelename for ?uri2 of the selected item (if available).',
        },
        {
          name: 'label',
          type: 'text',
          label: 'Label',
        },
        {
          name: 'extrastyle',
          type: 'text',
          label: 'extra css style (border-radius:20px)',
        },
      ],
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.preSelect()
    }
  }

  componentDidUpdate(prevProps) {
    try {
      if (!isEqual(prevProps.data, this.props.data)) {
        this.preSelect()
      }
    } catch (e) {
      console.log(e)
    }
  }

  preSelect() {
    try {
      //console.log("preselect");
      var value = 'http://www.buildingbits.nl/null'
      let item = restructureResponse(this.props.data)[0]
      if (item == null) {
      } else {
        let keyURI = this.props.data.head.vars[0]
        value = item[keyURI]
      }

      if (this.props.publishVariable != null) {
        console.log(
          'select publishing ',
          this.props.publishVariable,
          value,
          item
        )
        this.props.publish(this.props.publishVariable, value)
      }
      if (this.props.publishVariable2 != null && item['uri2'] != null) {
        console.log('initial select publishing uri2 ', item['uri2'])
        this.props.publish(this.props.publishVariable2, item['uri2'])
      }
    } catch (e) {
      console.log(e)
    }
  }

  onChange = (event) => {
    let value = event.target.value
    let value2 = null

    try {
      value2 = event.target[event.target.selectedIndex].attributes['value2']
    } catch (e) {}

    if (this.props.publishVariable != null) 
    {
      this.props.publish(this.props.publishVariable, value)
    }

    if (this.props.publishVariable2 != null && value2 != null) 
    {
      console.log('select publishing uri2 ',this.props.publishVariable2,value2);
      this.props.publish(this.props.publishVariable2, value2)
    }
  }

  render() {
    if (!this.props) return null
    if (!this.props.data) return null
    let headVars = this.props.data.head.vars
    if (headVars.length < 2) return null
    let keyURI = headVars[0] // graph
    let keyLabel = headVars[1] // label
    let items = null
    try {
      items = restructureResponse(this.props.data).filter(not(isEmptyObject))
    } catch (er) {
      console.log(er)
      return null
    }
    if (items == null) return null
    let value = this.props.pubsub[this.props.publishVariable]
    const stylePanel = styles[this.props.panelstyle]
    const stylePrimaryAction = this.props.colorPrimaryAction ? 'primaryColor' : ''

    return (
      <div className={styles.main}>
        <div className={styles.label}>{this.props.label}</div>
        <Input
          className= {`form-control ${styles.round} ${stylePanel} ${stylePrimaryAction}`}
          value={value || ''}
          type="select"
          onChange={this.onChange}
        >
          {/* <option value="">Kies een {this.props.publishVariable}...</option> */}
          {items.map((item) => (
            <option
              key={item[keyURI]}
              value={item[keyURI]}
              value2={item['uri2']}
            >
              {item[keyLabel]}
            </option>
          ))}
        </Input>
      </div>
    )
  }
}

export default SelectBox
