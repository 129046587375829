
import { lazy } from 'react';
export default class BBDFormDescriptor  {
 
  static getDescription() {
    return {
      name: 'BBDForm',
      component: lazy(() =>  import('./BBDForm')),
      label: 'BBDForm',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de form',
        },
        {
          name: 'class',
          type: 'text',
          label: 'uri van de owl/shacl classe',
        },
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the form. Use graph,title,uri, propUri, label,value, type (xsd,select), optionLabel optionValue ?required regex, multiple,objectOfCls, disabled ',
        },
        {
          name: 'cancelbutton',
          type: 'boolean',
          label: ' show  cancel button.',
        },
       
        {
          name: 'disabled',
          type: 'boolean',
          label: 'disabled',
        },
        {
          name: 'emptyText',
          type: 'text',
          label: 'text by empty sparql results',
        },
        {
          name: 'submit',
          type: 'select',
          label: 'submit handling ',
          options: [
            { label: 'none', value: 'none' },
            { label: 'normal', value: 'normal' },
            { label: 'internal parameters', value: 'internalparameters' },
            { label: 'submit to sparql endpoint', value: 'sparqlendpoint' },
            { label: 'javascript', value: 'javascript' },
          ],
        },
      ],
    }
  }

  
}
