import './bbutil'
import BBIFrame from './BBIFrame'

class BBYasguiLatest extends BBIFrame {
  constructor() {
    super()

    this.scrolling = 'yes'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBYasguiLatest',
      component: BBYasguiLatest,
      label: 'BB YasGUI Latest',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            '?sparql ?options variabel (single). getOptions() is available in yasgui ',
        },
        {
          name: 'queryOnly',
          type: 'boolean',
          label: 'show only yasque ',
        },
      ],
    }
  }

  getUrl() {
    //return "/bb/bbyasgui.html";
    //return '/bb/static/iframes/yasgui/yasguiConf.html'
    return '/bb/static/iframes/yasgui/yasguiConfLatest.html'
  }
}

export default BBYasguiLatest
