import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import styles from './Login.module.css'
import { Button, Input, FormGroup, Label } from 'reactstrap'
import axios from 'axios'
import Header from './Header'
import { Helmet } from 'react-helmet'
import Footer from './Footer'

class Login extends Component {
  state = {
    email: '',
    password: '',
  }

  login = (values, actions) => {
    actions.setSubmitting(true)
    axios({
      method: 'post',
      url: '/api/login',
      data: values,
    })
      .then(({ data }) => {
        localStorage.setItem('kvk-vb-token', data.token)
        this.props.dispatch({
          type: 'FETCH_ME_SUCCESS',
          me: data.me,
        })
        this.props.history.replace(this.props.location.state || '/')
        window.location.reload(false)
      })
      .catch((error) => {
        actions.setStatus({ msg: error.response.data })
        this.props.dispatch({
          type: 'FETCH_ME_FAILURE',
        })
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Connected Apps</title>
          <link rel="icon" type="image/png" href="/favicon.png" sizes="16x16" />
        </Helmet>
        <Header noLoginButton />
        <div className={styles.mainContainer}>
          <div className={styles.main}>
            <div className={styles.formContainer}>
              <Formik
                initialValues={{ email: '', password: '' }}
                validate={(values) => {
                  let errors = {}
                  if (!values.email) {
                    errors.email = 'Required'
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = 'Invalid email address'
                  }
                  return errors
                }}
                onSubmit={this.login}
              >
                {({ errors, status, touched, isSubmitting }) => (
                  <Form className={styles.form}>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        tag={Field}
                        id="email"
                        name="email"
                        type="email"
                        component="input"
                      />
                      <ErrorMessage
                        className="error"
                        name="email"
                        component="div"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        tag={Field}
                        id="password"
                        name="password"
                        type="password"
                        component="input"
                      />
                      <ErrorMessage
                        className="error"
                        name="password"
                        component="div"
                      />
                    </FormGroup>
                    {status && status.msg && <div>{status.msg}</div>}
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

Login = withRouter(Login)

export default connect()(Login)
