import './bbutil'
import BBIFrame from './BBIFrame'

class BBNOML extends BBIFrame {
  constructor() {
    super()

    this.scrolling = 'no'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBNOML',
      component: BBNOML,
      label: 'BBNOML',
      variables: [
        {
          name: 'data',
          type: 'textarea',
          label: 'noml input',
        },
      ],
    }
  }

  getUrl() {
    return '/bb/noml.html'
  }
}
export default BBNOML
