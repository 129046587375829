import React, { Component } from 'react'
import yasqe from 'yasgui-yasqe'
import '../../node_modules/yasgui-yasqe/dist/yasqe.css'
// import { Button } from 'reactstrap';

class Yasgui extends Component {
  constructor(props) {
    super(props)
    this.textArea = React.createRef()
  }

  componentDidMount() {
    this.yas = yasqe.fromTextArea(this.textArea.current)
    this.yas.setValue(this.props.value || '')
    this.yas.on('blur', () => {
      this.props.handleBlur(this.yas.getValue())
    })
  }

  render() {
    return <input ref={this.textArea} type="textarea" />
  }
}

export default Yasgui
