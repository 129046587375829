import axios from 'axios'

class Command {
  static sendCommand(component, command, options, rv) {
    if (options == null) options = {}
    let repoURL = component.props.repoURL
    if (repoURL != null && repoURL.endsWith('sparql')) {
      let commandUrl = repoURL.replace('sparql', 'command')
      const formData = new FormData()
      formData.append('commando', command)
      options.commando = command
      if (options != null) {
        for (let key in options) {
          formData.append(key, options[key])
        }
      }
      axios.defaults.withCredentials = true
      axios.defaults.credentials = 'include'

      try {
        axios
          .post(commandUrl, options, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json',
            },
          })
          .then(function (arg) {
            console.log('SUCCESS command', arg)
            if (rv != null) {
              rv.call(this, arg)
            }
          })
      } catch (e) {
        console.log('command error ', e)
        alert('command error')
      }
    }
  }

  static sendCommand2(options) {
    let repoURL = window.top.url
    if (repoURL != null && repoURL.endsWith('sparql')) {
      let commandUrl = repoURL.replace('sparql', 'command')
      const formData = new FormData()
      if (options != null) {
        for (let key in options) {
          formData.append(key, options[key])
        }
      }
      axios.defaults.withCredentials = true
      axios.defaults.credentials = 'include'

      try {
        axios
          .post(commandUrl, options, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json',
            },
          })
          .then(function (arg) {
            console.log('SUCCESS command', arg)
          })
      } catch (e) {
        console.log('command error', e)
      }
    }
  }

  static ruleRule(uri) {
    var json = {
      commando: 'VRCommands',
      command2: 'runSparqlRule',
      command3: uri,
    }
    Command.sendCommand2(json)
  }
  static runRulesWithTag(tag) {
    console.log('run rules met specifieke tag ' + tag)
    var json = {
      commando: 'VRCommands',
      command2: 'runSparqlRulesWithTag',
      command3: tag,
    }

    Command.sendCommand2(json)
  }
}

window.top.command = Command

export default Command
