const initialState = {
  loading: true,
  isAuthenticated: false,
}

export default function handleMe(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_ME_SUCCESS':
      return {
        loading: false,
        isAuthenticated: true,
        ...action.me,
      }
    case 'FETCH_ME_FAILURE':
      return {
        loading: false,
        isAuthenticated: false,
      }
    default:
      return state
  }
}
