import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as extraIcon from '@fortawesome/free-solid-svg-icons'


library.add(
  extraIcon.faCheckSquare,
  extraIcon.faPercent,
  extraIcon.faGavel,
  extraIcon.faHandPointLeft,
  extraIcon.faCheck,
  extraIcon.faCircle,
  extraIcon.faDrawPolygon,
  extraIcon.faInfo,
  extraIcon.faSquare,
  extraIcon.faSquareFull,
  extraIcon.faPlus,
  extraIcon.faCogs,
  extraIcon.faBullseye,
  extraIcon.faDotCircle,
  extraIcon.faDiceD6,
  extraIcon.faDatabase,
  extraIcon.faBox,
  extraIcon.faBoxOpen,
  extraIcon.faCube,
  extraIcon.faCoffee,
  extraIcon.faCube,
  extraIcon.faStop,
  extraIcon.faListUl
)

export { FontAwesomeIcon as Icon }
