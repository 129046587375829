import axios from 'axios'

class LoginHelper {
  static logoff(repoUrl) {
    if (repoUrl == null) {
      repoUrl = window.top.url
    }
    var ilUrl = repoUrl.replace('sparql', 'logout')
    var dataSend = {}
    axios.defaults.withCredentials = true
    axios.defaults.credentials = 'include'
    axios(
      {
        method: 'post',
        url: ilUrl,
        data: dataSend,
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then(({ data, iets }) => {
      console.log('logoff ', data)
    })
  }

  static login(repoUrl, repo, login, psw, rv) {
    var ilUrl = repoUrl.replace('sparql', 'login')
    var dataSend = { login: login, psw: psw } //applogin:true,
    console.log('login', ilUrl, rv)

    axios.defaults.withCredentials = true
    axios.defaults.credentials = 'include'
    axios(
      {
        method: 'post',
        url: ilUrl,
        data: dataSend,
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then(
      ({ data, iets }) => {
        //     console.log("got login results ",data,rv);
        try {
          if (data.loggedIn === 'TRUE') {
            if (repo != null && data.repo != null) {
              if (repo === data.repo) {
                axios.defaults.withCredentials = true
                // console.log("alles is ok. calling rv functie ",rv);
                if (rv != null) {
                  rv.call(this, this)
                }
              } else {
                console.log('niet ok ', repo, data)
                //window.location.reload(false);
              }
            }
          } else {
            //   console.log(data);
            alert('could not log in. Wrong name or password.')
            //  window.location.reload(true);
          }
        } catch (error) {
          console.log('error', error)
        }
      },
      ({ error, iets }) => {
        console.log('error in sending ', error, iets, ilUrl)
        alert('could not log in. general error')
        window.location.reload(true)
      }
    )
  }

  static isLoggedIn(ilUrl, repo) {
    axios.defaults.withCredentials = true
    axios.defaults.credentials = 'include'

    axios(
      {
        method: 'get',
        url: ilUrl,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    ).then(
      ({ data }) => {
        try {
          if (data.loggedIn === 'TRUE') {
            //   console.log("confirmed. you are loged in. need to check for the correct repo",data,this);
            if (repo != null) {
              if (data.repo === repo) {
                // console.log("repo komt overeen");
              } else {
                //   console.log("repo komt niet overeen. ");
                this.props.history.replace(this.props.location.state || '/')
              }
            } else {
              this.props.history.replace(this.props.location.state || '/')
            }
          } else {
            console.log('not confirmed. you are not logged in (???)')
          }
        } catch (e) {
          console.log('catch error', e)
        }
      },
      ({ error }) => {
        console.log('netwerk error ', error)
      }
    )
  }
}

window.top.logoff = LoginHelper.logoff
export default LoginHelper
