export function getCgiUrl(
  cgiService: '' | 'login' | 'isloggedin' | 'sparql',
  repoUrl: string,
  localRepoUrl?: string
) {
  const absoluteRepoUrl = getAbsoluteRepoUrl(repoUrl, localRepoUrl)
  return absoluteRepoUrl.replace('sparql', cgiService)
}

function getAbsoluteRepoUrl(repoUrl: string, localRepoUrl?: string) {
  const { origin } = window.location
  const url = origin.includes('localhost') && localRepoUrl ? localRepoUrl : repoUrl

  if (url.startsWith('http://') || url.startsWith('https://')) return url
  if (url.startsWith('/')) return origin + url
  return `${origin}/${url}`
}
