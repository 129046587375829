import React, { Component, Fragment } from 'react'
import styles from './Table2.module.css'

import {
  restructureResponse,
  generateEmptyResponse,
} from '../../helpers/sparql'

// import { Table as StrapTable } from 'reactstrap';

function camelCaseToSpaces(str) {

 
  
  str = str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ').toLowerCase()
  return str.charAt(0).toUpperCase() + str.slice(1)
}




class TableRow extends Component 
{

  getHeader(s)
{
  try
  { 
    var json=JSON.parse(this.props.replaceHeaderJSON);
      var rs =json[s];
      if (rs!=null)
      return rs;
  }
  catch(e)
  {}
  return camelCaseToSpaces(s);
}


  onClick = function (uri, url) {
   
   if ((uri!=null) && (uri=="http://www.example.org/5c3c96ea1d83d8396b87bc0b") ) return; // uri is used for createing an example... 
    try
    {
          if ((uri!=null) && (uri.toString().substring(0,15)=="function link()") && (url==null) )
          {
            //console.log("no click action");
          return;
          }
    }catch(e){}
   
    if (url != null) {
      this.openLink(url)
      return
    }
    try {
      eval(this.props.onClick) //eslint-disable-line no-eval
    } catch (error) {
      console.log(error)
    }
    setTimeout(() => {
      if (uri==null) return;
      if (uri.length<2) return;
      this.props.publish(this.props.publishVariable, uri)
    }, 0)
    // console.log(uri);
  }

  openLink = (url) => {
    window.open(url, '_blank')
  }

  render() {
    let valueObject = this.props.valueObject
    let secondValueObject = null
    try {
      if (valueObject != null && valueObject.value != null) {
        if (valueObject.value.includes('&&')) {
          secondValueObject = valueObject.value.split('&&')[1]
          valueObject.value = valueObject.value.split('&&')[0]

          //console.log(valueObject.value,secondValueObject);
        }
      }
    } catch (e) {
      console.log(e)
    }
    let index = this.props.index
    let html = this.props.html
    let rawHTML = valueObject.value;
    
    return (
      <Fragment>
      <tr className={index !== undefined ? styles.list : ''}>
        <td>{!index && this.getHeader(this.props.headVar)}</td>

        {valueObject.link || valueObject.link2 ? (
          <td
            className={styles.link}
            onClick={() => this.onClick(valueObject.link, valueObject.link2)}
          >
            {
              (html = true ? (

                <div dangerouslySetInnerHTML={{ __html: rawHTML }}/>
              ) : (
                <td> <div> {""+valueObject.value} </div></td>
              ))
            }
          </td>
        ) : html ? (
          <td>
            <div dangerouslySetInnerHTML={{ __html: rawHTML }} />
          </td>
        ) : (
          <td> {valueObject.value} </td>
        )}

        {secondValueObject != null && <td> {secondValueObject} </td>}
      </tr>
      </Fragment>
    )
  }
}





class Table2 extends Component {
  static getDescription() {
    return {
      name: 'Table2',
      component: Table2,
      label: 'Table2 widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill table. If table item should be a link, than the you should add a property with the same name with suffix "URI", eg: ?object ?objectURI. For HTML rendering add the suffix "HTML".',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which tree item URI is published, when a link in the table  is clicked.',
        },
        {
          name: 'replaceHeaderJSON',
          type: 'text',
          label:
            'Key value JSON that replaces parameter headers. {"label":"naam (volledig)"}',
        },

        {
          name: 'renderEmpty',
          type: 'boolean',
          label: 'render an empty table',
        },
        {
          name: 'zeroResult',
          type: 'text',
          label:
            'text to display when sparql results is valid but has zero results',
        },
        {
          name: 'isFirstItemBold',
          type: 'boolean',
          label: 'First item bold',
        },
        {
          name: 'vertical',
          type: 'text',
          label: 'vertical presentation',
        },
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de tabel',
        },
        {
          name: 'onClick',
          type: 'javascript',
          label: 'Code that runs before link click event.',
        },
      ],
    }
  }

  createStateValues(items) {
    let res = {}
    for (var i = 0; i < items.length; i++) {
      let item = items[i]
      Object.keys(item).forEach((key) => {
        if (key.endsWith('URI')) return
        if (key.endsWith('URL')) return
        let obj = { value: item[key] }

        if (item[key + 'URI']) obj.link = item[key + 'URI']
        if (item[key + 'URL']) obj.link2 = item[key + 'URL']
        if (!res[key]) {
          res[key] = obj
        } else if (Array.isArray(res[key])) {
          if (!res[key].filter((re) => re.value === obj.value).length) {
            res[key].push(obj)
          }
        } else if (res[key].value !== item[key]) {
          res[key] = [res[key], obj]
        }
      })
    }

    Object.keys(res).forEach((key) => {
      if (Array.isArray(res[key])) {
        res[key].sort((a, b) => a.value.localeCompare(b.value))
      }
    })

    return res
  }
  shouldComponentUpdate22(props,state)
  {
   
    let data = this.props.data
    //  console.log(data);
    if (!data || !data.results || data.results.bindings.length === 0) {
      if (this.props.renderEmpty) {
        data = generateEmptyResponse(this.props.query);
       
        if ((this.state.loading==null ) || (this.state.loading==true) )
        {
          this.setState({loading:false} );
          

        }
        
        
      } 
    }
    return true;

  }

  

  render() {
    //console.log("table 2 render function ");
    let data = this.props.data
    //  console.log(data);
    if (!data || !data.results || data.results.bindings.length === 0) {
      if (this.props.renderEmpty) {
        
        data = generateEmptyResponse(this.props.query);
      
        
      } else {
        return null
      }
    }

    try {
      if (this.props.zeroResult != null) {
        if (
          this.props.zeroResult != null &&
          this.props.data.results &&
          this.props.data.results.bindings.length === 0
        ) {
          var label = this.props.zeroResult
          return (
            <div className={styles.main}>
              <p>{label}</p>
            </div>
          )
        }
      }
    } catch (e) {}








    let items = restructureResponse(data)
    if (!items.length) return null
    let valuesObj = this.createStateValues(items)

    //if (this.state.loading) {console.log("table2 loading");}

    let disabled = false
    if (this.state != null) {
      if (this.state.loading) {
        disabled = true
       // console.log('Disabling table2 ')
      }
    }

    return (
      <div
        disabled={disabled}
        className={
          styles.main +
          (this.props.isFirstItemBold ? ' ' + styles.firstItemBold : '')
        }
      >
        {this.props.title && (
          <div className={styles.headertxt}>{this.props.title}</div>
        )}
        <table>
          {/* <thead>
          <tr>
            {data.head.vars.map((headVar, index) => (
              <th key={index}>{headVar}</th>
            ))}
          </tr>
        </thead> */}
          <tbody>
            {data.head.vars.map((headVar) => {
              if (headVar.endsWith('URI')) {
                return null
              }
              if (headVar.endsWith('URL')) {
                return null
              }
              let valueObject = valuesObj[headVar]
              //if (!valueObject) return null;
              if (!valueObject) valueObject = ''
              var html = false
              if (headVar.endsWith('HTML')) {
                headVar = headVar.substr(0, headVar.length - 4)
                html = true
              }

              if (Array.isArray(valueObject)) {
                return valueObject.map((val, index) => {
                  return (
                    <TableRow
                      {...this.props}
                      key={index}
                      index={index}
                      valueObject={val}
                      headVar={headVar}
                      html={html}
                    />
                  )
                })
              } else {
                return (
                  <TableRow
                    key={headVar}
                    {...this.props}
                    index={0}
                    valueObject={valueObject}
                    headVar={headVar}
                  />
                )
              }
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Table2
