export default function handlePubSub(state = {}, action) {
  //console.log(action);

  switch (action.type) {
    case 'PUBLISH':
      return {
        ...state,
        ...action.data,
      }
    case 'PUBLISH2': {
      console.log('publish 2', state, action.data)
      return state
    }
    default:
      return state
  }
}
