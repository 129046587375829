import './bbutil'

import React, { Component } from 'react'
import Spinner from '../../Spinner'

import Upload from 'react-widget-upload'
import styles from './BBUpload.module.css'
import PublishUtils from '../../../helpers/PublishUtils'
import 'react-widget-upload/style'

import axios from 'axios'

/*
//class BBUpload extends BBIFrame {
  constructor222()
  {
     super();
  
     this.scrolling="true";
     this.noEvents=false;
 
  }

*/

class BBUpload extends Component {
  state = {
    files: [],
    enabled: true,
  }

  downloadReport(key, file) {
    let url = this.props.serverUrl
    if (url == null) {
      alert('no download url defined')
      return
    }

    if (!url.startsWith('http')) {
      url = this.props.repoURL
    }
    url = url.replace('sparql', 'download')
    console.log('download ', url, key)
    //const formData = new FormData();
    //formData.append("fileToDownload",key);
    //formData.append("test","hans");
    let o = {}

    if (key == null) {
      key = ''
    }
    o.fileToDownload = key
    var me = this

    axios.defaults.withCredentials = true
    axios.defaults.credentials = 'include'
    axios
      .post(url, null, { params: o })
      .then(function (data) {
        console.log('success data download ', data)
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]))

        const link = document.createElement('a')

        link.href = downloadUrl
        if (file == null) {
          file = 'file.txt'
        }
        link.setAttribute('download', file) //any other extension

        document.body.appendChild(link)

        link.click()

        link.remove()

        setTimeout(function () {
          me.setState({ enabled: true })
        }, 5000)
      })
      .catch(function (e) {
        alert('error downloading')
        console.log('FAILURE!!', e)
      })
  }

  handleChange = (files) => {
    this.setState({
      files,
      enabled: false,
    })

    var me = this
    if (!files.length) return
    const file = files[0]

    const formData = new FormData()
    formData.append('command', this.props.command)
    formData.append('file', file, file.name)
    axios.defaults.withCredentials = true
    axios.defaults.credentials = 'include'
    let url = this.props.serverUrl
    if (url == null) {
      alert('no upload url defined')
      return
    }

    if (!url.startsWith('http')) {
      url = this.props.repoURL.replace('sparql', url)
    }
    //console.log(this.props,url);
    axios
      .post(url, formData)
      .then(function () {
        console.log('SUCCESS upload!!')

        if (me.props.successEval != null) {
          console.log('executing after upload ', me.props.successEval)
          try {
            eval(me.props.successEval) //eslint-disable-line no-eval
          } catch (e) {
            console.log(e)
          }
          PublishUtils.setTimeStampParameter(me, true)
        }
        me.setState({ enabled: true })
      })
      .catch(function (e) {
        //console.log('upload failure ',e);
        alert('could not upload file')
        me.setState({ enabled: true })
      })
  }

  render() {
    const enabled = this.state.enabled
    var text = this.props.uploadText

    if (text == null) {
      text = 'upload'
    }
    if (enabled === false) {
      text = this.props.processingText
      if (text == null) {
        text = 'processing'
      }
      // return  (<Spinner loading="true" ></Spinner>)
    }

    return (
      <div className={styles.uploadtest}>
        <Spinner loading={!enabled}> </Spinner>
        <Upload
          onChange={this.handleChange}
          className={styles.upload1}
          openFileDialogOnClick={enabled}
          droppable={enabled}
        >
          <div className={styles.text}>{text} </div>
        </Upload>
      </div>
    )
  }
  static getDescription() {
    return {
      name: 'BBUpload',
      component: BBUpload,
      label: 'BB Upload widget',
      variables: [
        {
          name: 'serverUrl',
          type: 'text',
          label: 'server url (full url or upload)',
        },
        {
          name: 'command',
          type: 'text',
          label: 'command for server  {validateGarantie}',
        },
        {
          name: 'uploadText',
          type: 'text',
          label: 'text for the widget',
        },
        {
          name: 'processingText',
          type: 'text',
          label: 'text for the widget when uploading and processing',
        },
        {
          name: 'fileExtensions',
          type: 'text',
          label: 'fileExtensions xslx,js',
        },
        {
          name: 'fields',
          type: 'json',
          label: 'json for w2uiform fields',
        },
        {
          name: 'tabs',
          type: 'text',
          label: 'optional w2ui tabs definitie',
        },

        {
          name: 'multiple',
          type: 'boolean',
          label: 'multiple files ',
        },
        {
          name: 'autoUpload',
          type: 'boolean',
          label: 'auto upload ',
        },
        {
          name: 'successEval',
          type: 'text',
          label:
            'javascript code after successfull upload... e.g. me.downloadReport("garantierapport","garantie.html") ',
        },

        PublishUtils.getTimestampParametersDef(),
      ],
    }
  }

  /*
  getUrl()
  {
      return "/bb/upload/configurableUploaderReact.html";
  }
  */
}

export default BBUpload
