import { Component } from 'react'
import styles from './Tree.module.css'
import { restructureResponse } from '../../helpers/sparql'
import { BrowserRouter as Router, BrowserRouter, Link } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHandPointLeft,
  faPercent,
  faCoffee,
  faCube,
  faCheck,
  faDrawPolygon,
  faCircle,
  faInfo,
  faSquare,
  faSquareFull,
  faPlus,
  faBox,
  faCogs,
  faDotCircle,
  faDatabase,
  faDiceD6,
  faBullseye,
  faBoxOpen,
  faCheckSquare,
  faGavel,
  faStop,
  faListUl,
} from '@fortawesome/free-solid-svg-icons'


library.add(
  faCheckSquare,
  faPercent,
  faGavel,
  faHandPointLeft,
  faCheck,
  faCircle,
  faDrawPolygon,
  faInfo,
  faSquare,
  faSquareFull,
  faPlus,
  faCogs,
  faBullseye,
  faDotCircle,
  faDiceD6,
  faDatabase,
  faBox,
  faBoxOpen,
  faCube,
  faCoffee,
  faCube,
  faStop,
  faListUl
)

//console.log(faHandPointLeft.iconName);

class TreeItem extends Component {
  state = {
    childrenVisible: this.props.depth >0 ,
  }

  determineVisibility(item) {
    if (!item.children) return
    if (item.containsSelection) {
       this.setState({
        childrenVisible: true
      });
    } else {
      if (this.props.shouldNonSelectedSubTreeClose) {
        this.setState({
          childrenVisible: false,
        })
      }
    }
  }

  componentDidMount() {
    if (!this.props.selectedItem) return
    this.determineVisibility(this.props.item)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.selectedItem) return
    if (prevProps.selectedItem === this.props.selectedItem) return
    this.determineVisibility(this.props.item)
  }

  treeItemClicked = () => {
   
    this.props.treeItemClicked(this.props.item)
  }

  arrowClicked = () => {
    this.setState({
      childrenVisible: !this.state.childrenVisible,
    })
  }

  renderArrow(item) {
    if (!item.children) return <span className={styles.treeArrow} />
    if (this.state.childrenVisible) {
      return (
        <span
          onClick={this.arrowClicked}
          className={styles.treeArrow + ' ' + styles.down}
        />
      )
    } else {
      return (
        <span
          onClick={this.arrowClicked}
          className={styles.treeArrow + ' ' + styles.right}
        />
      )
    }
  }

  handlePublishingUri2OrNot(item, treeItemURIKey) {
    if (this.props.selectedItem === item[treeItemURIKey]) {
      let publishVariable2 = this.props.publishVariable2

      if (publishVariable2) {
        var current = this.props.pubsub[publishVariable2]
        var contender = item['URI2']
        //  console.log("found selected item ",item,publishVariable2,current,contender);
        if (current !== contender) {
          // console.log("publishing uri2 ",publishVariable2, item["URI2"]);
          // this.props.publish(publishVariable2, item["URI2"]);
        }
      }
    }
  }

  render() {
    let item = this.props.item
    let labelKey = this.props.data.head.vars[0]
    let treeItemURIKey = this.props.data.head.vars[1]
    // let setSelectionInURL = this.props.setSelectionInURL;
    let setSelectionInURL = false // code kan nog opgeschoond worden. implementatie verloopt nu simpeler

    // sorteren doen we voorlopig even via sparql
    /*
    if (item.children) {
      item.children.sort((a, b) => {
        if (!a[labelKey]) return 0;
        return a[labelKey].localeCompare(b[labelKey]);
      });
    }
    */

    if (item.icon == null) {
      if (this.props.defaultIcon !== '') {
        item.icon = this.props.defaultIcon
      }
    }
    this.handlePublishingUri2OrNot(item, treeItemURIKey)

    if (this.props.selectedItem === item[treeItemURIKey]) {
    //  console.log('selected item ')
    }

    /*

    if (this.props.selectedItem === item[treeItemURIKey])
    {
    
      let publishVariable2 = this.props.publishVariable2;
      if (publishVariable2)
      {
        var current=this.props.pubsub[publishVariable2];
        var contender = item["URI2"];
      //  console.log(current,contender,publishVariable2);
        if (current!=contender)
        {
         
          this.props.publish(publishVariable2, item["URI2"]);
          if (setSelectionInURL)
          {
           // console.log("we moeten publish variable 2  uitvoeren incl url rewrite");
          }
          else
          {
           // console.log("we moeten publish variable 2  uitvoeren");
          }
        }
        
      }
    }
    */

    let curi = 'oude implementatie'

    // <FontAwesomeIcon icon="info" size="xs" transform="down-6; right-4" />
    return (
      <Router >
        <div  
          className={styles.treeItem}
          id={
            this.props.selectedItem === item[treeItemURIKey]
              ? item[treeItemURIKey]
              : null
          }
        >
          <div className={styles.treeLabel}>
            {this.renderArrow(item)}
            {item.icon != null && (
              <FontAwesomeIcon
                icon={item.icon}
                size="xs"
                transform="down-6; left-4"
                onClick={this.treeItemClicked}
              />
            )}
            {item.icon2 != null && (
              <FontAwesomeIcon
                icon={item.icon2}
                size="xs"
                transform="down-6; left-4"
                onClick={this.treeItemClicked}
              />
            )}
            {item.icon3 != null && (
              <FontAwesomeIcon
                icon={item.icon3}
                size="xs"
                transform="down-6; left-4"
                onClick={this.treeItemClicked}
              />
            )}
            {setSelectionInURL ? (
              <BrowserRouter basename="/" >
                <Link to={curi} >
                  <span
                    onClick={this.treeItemClicked}
                    className={
                      this.props.selectedItem === item[treeItemURIKey]
                        ? styles.selected
                        : ''
                    }
                  >
                    {item[labelKey]}
                  </span>
                </Link>
              </BrowserRouter>
            ) : (
              <span
                onClick={this.treeItemClicked}
                className={
                  this.props.selectedItem === item[treeItemURIKey]
                    ? styles.selected+ " "+styles.noselect + ' ' + styles.label
                    : 'anders '+styles.noselect + ' ' + styles.label
                }
              >
                {item[labelKey]}
              </span>
            )}
          </div>
          {item.children &&
            this.state.childrenVisible &&
            item.children.map((item, index) => {
              return (
                <TreeItem
                  key={index}
                  {...this.props}
                  item={item}
                  depth={this.props.depth - 1}
                />
              )
            })}
        </div>
      </Router>
    )
  }
}

class Tree extends Component {


 
    state = {
      items: []
         
  }
  constructor(props)
  {
    super(props);
    if (this.id==null)
    {
      if (Tree.nid==null) Tree.nid=0;
      Tree.nid++;
      this.id="tree"+Tree.nid;
    }
  
  }

  
  

  static getDescription() {
    return {
      name: 'Tree',
      component: Tree,
      label: 'Tree widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill tree. First item should be the object label, the second the object URI, the third the parent URI. optional you can use icon, icon2 and icon3 variables for fa-fa icons',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which tree item URI is published, when tree item is clicked.',
        },
        {
          name: 'publishVariable2',
          type: 'text',
          label:
            'Variabele in which tree item URI2 is published, when tree item is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Publish variabele on which to listen to to define tree item selection.',
        },
        {
          name: 'rootLabel',
          type: 'text',
          label: 'Label of the root of the tree. Leave empty for no root.',
        },
        
        {
          name: 'expandLevel',
          type: 'int',
          label: 'initially expand the tree to this level',
        },

        {
          name: 'title',
          type: 'text',
          label: 'title',
        },
        {
          name: 'defaultIcon',
          type: 'text',
          label: 'fa icon name when no icon is defined. ',
        },
        {
          name: 'aggregate',
          type: 'boolean',
          label: 'Aggregate the results based upon parent and child uri ',
        },
        {
          name: 'shouldNonSelectedSubTreeClose',
          type: 'boolean',
          label:
            'Should a subtree that does not contain selection fold on new selection.',
        },
        {
          name: 'setSelectionInURL',
          type: 'boolean',
          label: 'the selected URI will be used in the browser URL',
        },
        {
          name: 'setSelectionInURLStartsString',
          type: 'text',
          label:
            'the selected URI will be used in the browser URL only if it starts with',
        },
        {
          name: 'parseInitialURLForSelection',
          type: 'boolean',
          label: 'parse the initial URL for selection',
        },
        {
          name: 'initialSelection',
          type: 'text',
          label: 'initial uri selection',
        },
        {
          name: 'scrollSelectionIntoView',
          type: 'boolean',
          label: 'try to automatically scroll the selected object into view',
        },
      ],
    }
  }

  componentDidUpdate() {
    if (this.props.subscribeVariable == null) {
      return
    }
    if (this.selectEvent) {
      this.selectEvent = false
      return
    }
    
    this.scrollLatestUriIntoView();
  }

  findInChild(parent,id)
  {
    try
    {
    for (let i = 0; i < parent.children.length; i++) {
        var child =parent.children[i];
        console.log(child.id);
        if (child.id==id) return child;
    }

    for (let i = 0; i < parent.children.length; i++) {
      var child =parent.children[i];
         return this.findInChild(child,id)
  }
}
catch(e)
{console.log(e);}
  return null;

  }
  scrollLatestUriIntoView()
  {
   
       let selectedURI = this.props.pubsub[this.props.subscribeVariable]
    //console.log("tree component did update ",selectedURI);
    if (this.props.scrollSelectionIntoView === true) {
      //not sure if this works correctly
      // console.log("smooth tree scroll, ",selectedURI, this.state.latestTreeSelection);
      if (selectedURI != null && this.state.latestTreeSelection == selectedURI) {
         // console.log("no smooth tree scroll necessary",this.props["titleMenubar"]);
          return;
      } else {
            if (selectedURI != null) {
               var me=this;
          setTimeout(function () {
           
             const p=document.getElementById(me.id);
             

             if (p==null)return;
            // console.log("scroll into view ",me.id,selectedURI);
             
             
             var element = document.getElementById(selectedURI)
             if (p.contains(element))
             {
             // console.log("element contained by parent!");
             }
             else
             {
             // console.log("element not contained by parent!");
              element=null;
             }
            // console.log("tree component did update  element foud ",element);
            if (element != null) {
              console.log(" smooth tree scrolling ",me.props["titleMenubar"],selectedURI,me.state.latestTreeSelection,me.state);
    
              element.scrollIntoView({ behavior: 'smooth' })
            }
          }, 10)
        } else {
          // reset scroll
          const p=document.getElementById(this.id);
          if (p==null)return;
          var element = document.getElementById('begin')
          if (p.contains(element))
          {
          // console.log("element contained by parent!");
          }
          else
          {
          // console.log("element not contained by parent!");
           element=null;
          }
         // const element = this.findInChild(p,'begin');
         
          if (element != null) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }
    }
  }

  processURLSelection() {
    if (this.props != null) {
      if (this.props.parseInitialURLForSelection) {
        var url = window.top.location.href
        //  console.log("trying to find selection from url",url);
        if (url.startsWith(this.props.setSelectionInURLStartsString)) {
          //  console.log("try parsing url path",url);

          this.props.pubsub[this.props.subscribeVariable] = url
        } else {
          try {
            //  console.log("trying to find selection from url parameter");
            const queryString = window.top.location.search
            //  console.log(queryString);
            const urlParams = new URLSearchParams(queryString)
            var selection = urlParams.get('selection')
            if (selection != null) {
              if (
                selection.startsWith(
                  this.props.setSelectionInURLStartsString
                ) ||
                selection.startsWith('http://localhost:3000/')
              ) {
                //  console.log("try parsing url from selection",selection);
                this.props.pubsub[this.props.subscribeVariable] = selection
                return true
              } else {
                console.log(
                  'initial parametervalue not according to ',
                  this.props.setSelectionInURLStartsString
                )
              }
            } else {
              console.log('no initial selection parameter value found', url)
            }
          } catch (error) {
            console.log(error)
          }
        }

        //url="https://otl.waternet.nl/def/Document"; //voorbeeld
        // remove url parameters
      }
    }
    return false
  }

  componentDidMount() 
  {
    if (this.processURLSelection()) {
      return
    }
    //this.processURLSelection();
    // check of we een selectie (niet zeker of die bestaat) moeten registerern op basis van de url.
    if (this.props.initialSelection != null) {
      if (
        this.props.subscribeVariable != null ||
        this.props.publishVariable != null
      ) {
        // console.log("setting initial selection value");
        let selection = this.props.subscribeVariable
        if (selection == null) {
          selection = this.props.publishVariable
        }
        this.props.pubsub[selection] = this.props.initialSelection
      }
    }
  }
  shouldComponentUpdate222(props, state) // needs an update when uri is selected for highlighting
  {
    
    var sv=this.props.subscribeVariable;
    if (sv==null) return true;
    let selectedURI = this.props.pubsub[this.props.subscribeVariable]
    let nselectedURI=props.pubsub[props.subscribeVariable];
   
    if ((selectedURI!=nselectedURI) )
    {
      let publishVariable2 = this.props.publishVariable2;
      if (publishVariable2!=null)   
      {
        let selectedURI2 = this.props.pubsub[this.props.publishVariable2]
        let nselectedURI2=props.pubsub[props.publishVariable2];
        
        if (selectedURI2==nselectedURI2)
        {
         console.log("we should check variable 2?");
         var nselectedURI22= this.lookupPublishVariable2(nselectedURI);
         if (nselectedURI2!=nselectedURI22)
         {
          console.log("confirmed to update publisch varaible 2 ",nselectedURI22);
         }
        }

      }
    }

    
    return true;
    
  }
  lookupPublishVariable2(uri)
  {
    try{
    var var1=this.props.subscribeVariable;
    console.log(this.props);
    if (this.props.data==null) return null;
    //var var2=this.props.publishVariable2;
    for (var n in this.props.data.results.bindings) 
    {
      var binding = this.props.data.results.bindings[n];
      var value = binding[var1].value;
      if (value==uri)
      {
        
        return binding.URI2.value;
      }

    }
  }
  catch(e){console.log(e);}
  return null;
  }


  treeItemClicked = (item) => 
  {
    
   
    this.selectEvent = true
    
    // console.log("latest tree selection",item.uri);
    let treeItemURIKey = this.props.data.head.vars[1]
    let publishVariable = this.props.publishVariable
    let publishVariable2 = this.props.publishVariable2;
   // console.log(publishVariable2);
    if (publishVariable2) {
    //  console.log("two tree selection event publishing ",publishVariable2, item["URI2"]);
      //this.props.publish(publishVariable2, item["URI2"]);
      //setTimeout(function(){ props.publish(publishVariable, item[treeItemURIKey]);},10);
      var v1 = item[treeItemURIKey]
      var v2 = item['URI2']
 
    //  this.latestTreeSelection=v1;

    this.setState({ "selectedItem":v1 ,"latestTreeSelection":v1 });
       


      this.props.dispatch({
        type: 'PUBLISH',
        data: {
          [publishVariable]: v1,
          [publishVariable2]:v2
        },
      })
      if(true)return;
      var me = this
      setTimeout(function () {
        me.props.dispatch({
          type: 'PUBLISH',
          data: {
            //[publishVariable]: v1,
            [publishVariable2]: v2,
          },
        })
      }, 20)
    } else {
     
      v1 = item[treeItemURIKey]
    //  this.latestTreeSelection=v1;
   
   
    this.setState({ selectedItem:v1 ,latestTreeSelection:v1});
    
 //   console.log(this,this.state,v1);

   
      this.props.dispatch({
        type: 'PUBLISH',
        data: {
          [publishVariable]: v1,
        },
      })
      this.props.publish(publishVariable, item[treeItemURIKey])
    }


    
  }

  containsSelection(selectedURI, treeItemURIKey, item) {
    if (item[treeItemURIKey] == selectedURI) {
      return true
    }
    var b = false
    for (var n in item.children) {
      var child = item.children[n]
      //console.log(item,child);
      child.containsSelection = this.containsSelection(
        selectedURI,
        treeItemURIKey,
        child
      )
      if (child.containsSelection == true) {
        b = true
      }
    }
    return b
  }

  openSelectie(items) {
    let selectedURI = this.props.pubsub[this.props.subscribeVariable]
    if (selectedURI == null) {
      return
    }
    let treeItemURIKey = this.props.data.head.vars[1]
    for (var n in items) {
      var item = items[n]
      item.containsSelection = this.containsSelection(
        selectedURI,
        treeItemURIKey,
        item
      )
    }
  }

  findParents(ar) {
    let headVars = this.props.data.head.vars
    let treeItemURIKey = headVars[1]
    let parentItemURIKey = headVars[2]
    let parents = []
    let uri_items = {}
    // zoek parents en creeer uri hash
    for (var n in ar) {
      let item = ar[n]

      var itemsar = uri_items[item[treeItemURIKey]]
      if (itemsar == null) {
        itemsar = []
        uri_items[item[treeItemURIKey]] = itemsar
      }
      itemsar.push(item)
    }

    // zet children
    for (const n in ar) {
      var item = ar[n]
      if (uri_items[item[parentItemURIKey]] == null) {
        parents.push(item)
        // console.log("finding parent "+item[labelKey],parents[0]);
      }
      // console.log(parents) ;

      var parent = item[parentItemURIKey]
      if (parent != null) {
        itemsar = uri_items[parent]
        if (itemsar != null) {
          for (var i in itemsar) {
            var parentO = itemsar[i]
            var children = parentO.children
            if (children == null) {
              children = []
              parentO.children = children
            }
            children.push(item)
          }
        }
      }
    }

    return parents
  }

  aggregate(items) {
    try {
      let headVars = this.props.data.head.vars
      let treeItemURIKey = headVars[1]
      let parentItemURIKey = headVars[2]

      var id_binding = {}
      for (var n in items.results.bindings) {
        var binding = items.results.bindings[n]
        var parent = 'onbekend'
        if (binding[parentItemURIKey] != null)
          parent = binding[parentItemURIKey].value
        var id = parent + '_' + binding[treeItemURIKey].value
        var double = id_binding[id]
        if (double != null) {
          for (var key in binding) {
            double[key] = binding[key]
          }
        } else {
          id_binding[id] = binding
        }
      }
      var result = []
      //console.log(id_binding);
      for (var k in id_binding) result.push(id_binding[k])

      items.results.bindings = result
      // console.log(items);
      return items
    } catch (e) {
      console.log(e)
    }
    return null
  }

  /*shouldComponentUpdate()
  {
    console.log("tree needs to be updated");
    return true;
  }
*/
  exists(uri, item) {
    //console.log(uri,item);
    try {
      if (item.child === uri) {
        return true
      }
      if (item.children == null) {
        return false
      }
      for (var n in item.children) {
        var b = this.exists(uri, item.children[n])
        if (b) {
          return true
        }
      }
    } catch (e) {
      console.log(e)
    }

    return false
  }

  render() {
    if (!this.props.data) return null
    var item = this.state.rootitem
    if (item == null) {
      item = this.createTreeItems()
      //this.state.rootitem=item;
    }

    if (item == null) {
      return null
    }

    let selectedURI = this.props.pubsub[this.props.subscribeVariable]
    if (selectedURI == null) {
      selectedURI = this.state.selectedItem
    }

    if (selectedURI && this.props.setSelectionInURL) {
      var getUrl = window.top.location
      var baseUrl = getUrl.origin + '/'
      var suri = baseUrl + selectedURI.replace(baseUrl, '') // makesure it has the same origin
      if (suri !== selectedURI) {
        // console.log("selected uri not in this domain. resetting url ",selectedURI);
        suri = baseUrl
      }
      if (!this.exists(selectedURI, item)) {
        // console.log( "selected uri bestaat niet ",selectedURI);
        suri = baseUrl
      }
      // if (suri!=baseUrl)
      //console.log("should push suri ",suri);
      if (true) {
        //console.log("selected uri in this domain so changing url",selectedURI);
        setTimeout(function () {
          try {
            window.top.history.pushState({}, null, suri)
          } catch (e) {
            console.log(e)
          }
        }, 0)
      } else {
        console.log('not reloading')
      }
    }
    
    //console.log(this.props.data,item);
    var depth = 0
    if (this.props.expandLevel!=null) {depth=this.props.expandLevel;}

   // console.log(item,Array.isArray(item));
     if (Array.isArray(item))
     {
      return (
        <div id={this.id} className={styles.main + ' ' + styles[this.props.panelstyle]} disabled={this.state.loading}>
          {this.props.title && (
            <div className={styles.title}>{this.props.title}</div>
          )}
            {item.map((titem, index) => {
              return (
          <TreeItem
          key={index}
            {...this.props}
            item={titem}
            treeItemClicked={(item) =>this.treeItemClicked(item)}
            selectedItem={selectedURI}
           
            depth={depth}
          />
              )})}
        </div>
      )
     }
     else
     {
    return (
      <div id={this.id} className={styles.main + ' ' + styles[this.props.panelstyle]} disabled={this.state.loading}>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        <TreeItem
          {...this.props}
          item={item}
          treeItemClicked={(item) =>this.treeItemClicked(item)}
          selectedItem={selectedURI}
         
          depth={depth}
        />
      </div>
    )
        }
  }

  createTreeItems() {
    let selectedURI = this.props.pubsub[this.props.subscribeVariable]

    let items = this.props.data
    if (this.props.aggregate) {
      items = this.aggregate(items)
    }
    if (items == null) {
      return null
    }
    items = restructureResponse(items)
    if (items == null) {
      return null
    }

    let headVars = this.props.data.head.vars

    items = this.findParents(items)
    this.openSelectie(items)

    let labelKey = headVars[0]
    let item = {
      [labelKey]: this.props.rootLabel,
      children: items,
      containsSelection: !!selectedURI,
    }

    if (
      (this.props.rootLabel == '' || this.props.rootLabel == null) &&
      items.length == 1
    ) {
      item = items[0]
      // console.log("setting single data parent as root of the table",item);
    }
    else
    {
      item=items;
    }
    // console.log("returning tree items ",item);
    return item
  }
}

export default Tree
