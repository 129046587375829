import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'

class BBRDFViewerOnline extends BBIFrame {
  constructor() {
    super()

    this.scrolling = 'no'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBRDFViewerOnline',
      component: BBRDFViewerOnline,
      label: 'BB RDF Viewer from rdf.bg',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: ' Use ?file. or upcoming blob parameters',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        PublishUtils.getResetValueDef(),
      ],
    }
  }

  state = { selectedUri: null }

  getUrl() {
    //return "/bb/rdfviewer/indexNew.html";
    return 'https://www.3drotterdam.nl/'
  }
}
export default BBRDFViewerOnline
