import { PropsWithChildren } from 'react'
import { Fragment,Suspense } from 'react'
import styles from './Loader.module.css'
import Spinner from './Spinner'
import LoadingOverlay from 'react-loading-overlay'
import { PulseLoader } from 'react-spinners'
// @tsd-ignore
//LoadingOverlay.propTypes = undefined

export function Loader(props: PropsWithChildren<{
  loadStyle: 'classic' | 'overlay'
  active: boolean
}>) {
  if ( (props.loadStyle === 'classic') || (props.loadStyle === 'overlay'))
    return (
      <>
      <Suspense  fallback={ <Spinner loading={props.active} />} >
        <Spinner loading={props.active} />
        {props.children}
        </Suspense>
      </>
    )

/*
  if (props.loadStyle === 'overlay')
    return ( <LoadingOverlay
       className={styles.overlay}
      active={props.active}
      spinner={<PulseLoader size="8px" />} 
     >
        {props.children}
      </LoadingOverlay>
      )
      */
}
