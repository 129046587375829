import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'

export default class BBThreeJS extends BBIFrame {
  constructor() {
    super()

    this.scrolling = 'false'
    this.noEvents = false
  }

  getUrl() {
    // console.log("BB Table should getURL");

    return '/bb/w2ui/js/threejs/gltfviewer.html'
  }

  static getDescription() {
    return {
      name: 'BBThreeJS',
      component: BBThreeJS,
      label: 'BBThreeJS GLTF viewer',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the graph. Use node nodeLabel edge edgeLabel object objectLabel. optional hier (true false) for hierarchical rep. shape/shapeO (e.g circle) for node shape. objectType & nodeType voor kleur/groepering ',
        },
       
       

        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
              
       
        PublishUtils.getResetValueDef(),
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }
}
