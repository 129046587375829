
import { lazy } from 'react';

class BBPropertyPanelDescriptor {
  

  static getDescription() {
    return {
      name: 'BBPropertyPanel',
      component: lazy(() =>  import('./BBPropertyPanel')),
      label: 'BBPropertyPanel',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the property panel. Use ?property and ?value. optionally use  ?beschrijving  ?beschrijvingValue ?literal ?pred  ?iconColor ?icon',
        },
        {
          name: 'childParameterText',
          type: 'text',
          label:
            'string to replace in sparql with uri for getting child info (e.g. {{uri}})',
        },
        {
          name: 'renderEmpty',
          type: 'boolean',
          label: 'render an empty table',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }

  
}

export default BBPropertyPanelDescriptor
